<template>
  <th scope="col">
    <button
      v-if="sortable"
      type="button"
      class="mc-datatable__sort mc-datatable__btn"
      :class="sortDirection?.toLowerCase()"
      @click="() => toggleSort(value, sortDirection, additionnalProps)"
    >
      <span class="mc-datatable__sort-label">
        <span class="visually-hidden">sort by</span>
        <slot :props="props">
          {{ label }}
        </slot>
      </span>

      <span class="mc-datatable__sort-arrow" />
    </button>
    <slot v-else :props="props">
      {{ label }}
    </slot>
  </th>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MDataTableHeaderV2',
});
</script>

<script setup>
const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  sortable: {
    type: Boolean,
    default: false,
  },
  sortDirection: {
    type: String,
    default: null,
    validator: (value) => ['ASC', 'DESC'].includes(value),
  },
  sortDirectionDefault: {
    type: String,
    default: null,
    validator: (value) => ['ASC', 'DESC'].includes(value),
  },
  e2eSelector: {
    type: String,
    default: '',
  },
  additionnalProps: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(['update:sortDirection']);

function toggleSort(column, _sortDirection, additionnalProps) {
  let direction;
  switch (_sortDirection) {
    case 'ASC':
      direction = 'DESC';
      break;
    case 'DESC':
      direction = null;
      break;
    default:
      direction = 'ASC';
  }
  emit('update:sortDirection', { column, direction, ...additionnalProps });
}
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.datatable';
@import 'components/c.datatable-tools';
</style>
