import MDataTable from './MDataTable.vue';
import MDataTableHeader from './MDataTableHeader.vue';

MDataTable.install = (app) => {
  app.component('MDataTable', MDataTable);
};

MDataTableHeader.install = (app) => {
  app.component('MDataTableHeader', MDataTableHeader);
};

export { MDataTable, MDataTableHeader };
