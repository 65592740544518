<template>
  <MContainer class="mc-bottom-bar" :class="classObject" fluid>
    <!-- @slot Use this slot to insert the main content of the BottomBar -->
    <slot />
  </MContainer>
</template>

<script setup>
import { computed } from 'vue';
import MContainer from '../container/MContainer.vue';

/* props */
const props = defineProps({
  /**
   * Apply a `position: fixed` to the component and position it at the bottom of the page
   */
  fixed: {
    type: Boolean,
    default: false,
  },
});

/** computed */
const classObject = computed(() => {
  return {
    'mc-bottom-bar--fixed': props.fixed,
  };
});
</script>

<style lang="scss" scoped>
@import 'settings-tools/all-settings';

.mc-bottom-bar {
  @include set-box-shadow('s');

  align-items: center;
  background-color: $color-grey-000;
  display: flex;
  height: $mu400; // 64px

  &--fixed {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    width: 100%;
  }
}
</style>
