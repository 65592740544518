import MTab from './MTab.vue';
import MTabsV2 from './MTabsV2.vue';

MTab.install = (app) => {
  app.component('MTab', MTab);
};

MTabsV2.install = (app) => {
  app.component('MTabsV2', MTabsV2);
};

export { MTab, MTabsV2 };
