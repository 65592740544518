<template>
  <component
    :is="setComponent"
    v-bind="setAttributes(data)"
    class="mc-sidebar-segment"
    :class="classObject"
    @click="onClickSegment(data.code)"
  >
    <span class="mc-sidebar-segment__container">
      <MTooltip v-if="!open && withTooltip" :id="data.code" :text="data.label" position="right">
         <MIcon
           v-if="data.icon"
           :name="data.icon"
           class="mc-sidebar-segment__icon"
           color="#ffffff"
         />
      </MTooltip>
       <MIcon
         v-else-if="data.icon"
         :name="data.icon"
         class="mc-sidebar-segment__icon"
         color="#ffffff"
       />
      <span v-if="open" :aria-hidden="!open" class="mc-sidebar-segment__label">
        {{ data.label }}
      </span>
    </span>
  </component>
</template>

<script>
import MIcon from '../icon/MIcon.vue';
import MTooltip from '../tooltip/MTooltip.vue';

export default {
  name: 'MSidebarSegment',
  components: {MTooltip, MIcon },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    withTooltip: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['code-changed'],
  computed: {
    classObject() {
      return {
        'is-selected': this.data.code === this.selected.lv0?.code,
        'is-open': this.open,
        'is-close': !this.open,
      };
    },
    setComponent() {
      const data = this.data;
      const isLink = !!data.href;
      const routerLink = data.route ? 'router-link' : null;
      const defaultComponent = isLink ? 'a' : 'button';

      return routerLink ?? defaultComponent;
    },
  },
  methods: {
    setAttributes(data) {
      const id = data.code;
      const href = data.href ?? null;
      const type = !data.href ? 'button' : null;
      const to = data.route ?? null;

      return {
        ...data.attrs,
        id,
        href,
        to,
        type,
        'aria-label': this.open && data.label,
      };
    },
    onClickSegment(code) {
      this.$emit('code-changed', code);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'settings-tools/all-settings';

$color-grey: #5b737d;
$selected-Link: #007f8c;

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

.mc-sidebar-segment {
  @include set-font-scale('04', 'l');

  $parent: get-parent-selector(&);

  background: none;
  border: none;
  cursor: pointer;
  flex: 0;
  font-family: inherit;
  text-decoration: none;
  color: white;
  display: flex;
  overflow: hidden;
  min-height: 40px;
  padding: 0;
  width: 100%;

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 0px;
  }

  &__icon {
    flex-shrink: 0;
  }

  &__label {
    margin-left: 10px;
  }

  &.is-selected {
    background: $selected-Link;
  }

  &:focus-visible {
    box-shadow: none;
    border: none;
    outline: none;

    .mc-sidebar-segment__container {
      box-shadow: none;
      border: none;
      border-radius: 6px;
      outline: 1px solid #0b96cc;
    }
  }

  &:hover {
    background: #405d68;
  }

  &.is-close {
    #{$parent} {
      &__container {
        @include transition(margin 300ms ease-in-out);
        margin: 0 20px;
        width: 24px;
      }

      &__label {
        opacity: 0;
      }
    }
  }

  &.is-open {
    #{$parent}__container {
      @include transition(margin 300ms ease-in-out);
      margin: 0 32px;
    }

    &__label {
      opacity: 1;
    }
  }
}

:deep .mc-tooltip-container {
  cursor: pointer !important;
}

</style>
