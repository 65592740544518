<script>
import { h } from 'vue';
/**
 * The `MIcon` component is a **Vue.js** component allowing to have access to the icons of Mozaic Design System<br/>
 * All the icons available in the design system are accessible from [the associated documentation](https://mozaic.adeo.cloud/Foundations/Icons/).
 */
export default {
  name: 'MIcon',
  props: {
    /**
     * Icon name
     * @see [All icon names](https://mozaic.adeo.cloud/Foundations/Icons/catalog/)
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Icon color
     */
    color: {
      type: String,
      default: 'currentColor',
    },
  },
  data() {
    return {
      id: Math.random().toString().split('.')[1],
      viewbox: null,
      size: null,
      paths: null,
      type: null,
    };
  },
  computed: {
    loadIcons() {
      if (!this.name) {
        return null;
      }

      return () =>
        import(
          /* webpackMode: 'eager' */
          '@mozaic-ds/icons/js/icons.js'
        );
    },

    getIconName() {
      if (!this.name) {
        return null;
      }

      return this.name;
    },
  },
  watch: {
    name() {
      this.setIconElement();
    },
  },
  mounted() {
    this.setIconElement();
  },
  methods: {
    setIconElement() {
      this.loadIcons().then((icons) => {
        const iconName = this.getIconName;
        const selectedIcon = icons[iconName];

        if (!selectedIcon) {
          throw new Error(
            'The icon you are trying to display does not exist. Maybe the name is incorrect. => (See all available icons: http://mozaic.adeo.cloud/Foundations/Icons/catalog/)'
          );
        }

        this.viewbox = selectedIcon.viewBox;
        this.size = selectedIcon.size;
        this.paths = selectedIcon.paths;
        this.type = selectedIcon.type;
      });
    },
    setSizeFromViewbox(viewbox) {
      const viewboxSizes = viewbox.split(' ').filter((size) => size > 0);

      return {
        width: `${viewboxSizes[0]}px`,
        height: `${viewboxSizes[1]}px`,
      };
    },
  },
  render() {
    const paths = this.paths;
    const svgAttrs = {
      id: `MIcon-${this.name}-${this.id}`,
      viewBox: this.viewbox,
      height:
        this.type !== 'logotypes'
          ? this.size
          : this.setSizeFromViewbox(this.viewbox).height,
      width:
        this.type !== 'logotypes'
          ? this.size
          : this.setSizeFromViewbox(this.viewbox).width,
      fill: this.color,
      'aria-hidden': 'true',
    };

    function generatePaths(paths) {
      return paths.map(function (path) {
        const children = path.children;

        if (children) {
          return h(path.tagName, path.attrs, generatePaths(children));
        }

        return h(path.tagName, path.attrs);
      });
    }

    if (paths) {
      return h('svg', svgAttrs, generatePaths(paths));
    }

    return h('svg', svgAttrs);
  },
};
</script>
