<template>
  <div class="mc-accordion" :class="{ 'mc-accordion--s': size === 's' }">
    <input
      :id="id"
      ref="checkbox"
      type="checkbox"
      class="mc-accordion__trigger"
      :disabled="disabled"
      :checked="open"
      @change="onTrigger($event.target.checked)"
    />
    <div
      :class="['mc-accordion__header', setHeaderClasses]"
      :aria-expanded="open"
      @click="$refs.checkbox.click()"
    >
      <!-- @slot Use this slot to insert content before the label/title -->
      <slot name="beforeLabel" />
      <label
        :for="id"
        :aria-expanded="open"
        :class="['mc-accordion__header__label', setLabelClasses]"
        @click.stop
      >
        <m-icon v-if="icon" :name="icon" class="mc-accordion__icon" />
        {{ label }}
      </label>
      <!-- @slot Use this slot to insert content before the label/title -->
      <slot name="afterLabel" />
    </div>
    <div class="mc-accordion__content">
      <!-- @slot Use this slot to insert the main content of the accordion -->
      <slot />
    </div>
  </div>
</template>

<script>
import MIcon from '../icon/MIcon.vue';

/**
 * > Accordions allow you to show or hide related content under vertically stacked lists.
 *
 * The `MAccordion` component is the **Vue.js** implementation of the **Accordion** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Accordion/).
 */
export default {
  name: 'MAccordion',
  components: {
    MIcon,
  },
  props: {
    /**
     * The id of the accordion.
     * _Used to link the input element and the label element_
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * The label/title of the accordion
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Allows to manage the opening or closing of the component
     */
    open: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to define the size of the accordion
     * @values s, m
     */
    size: {
      type: String,
      default: null,
      validator: (value) => ['s', 'm'].includes(value),
    },
    /**
     * Allows to disabled the accordion
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to display or not the padding of the accordion label
     */
    padding: {
      type: Boolean,
      default: true,
    },
    /**
     * Allows to display an icon before the label of the accordion
     * @see [Available icons](https://mozaic.adeo.cloud/Foundations/Icons/)
     */
    icon: {
      type: String,
      default: null,
    },
  },
  emits: ['update:open'],
  computed: {
    setHeaderClasses() {
      return {
        'mc-accordion__label': true,
        'mc-accordion__label--icon': this.icon,
        'mc-accordion__label--no-padding': !this.padding,
      };
    },
    setLabelClasses() {
      return {
        'mc-accordion__header__label--with-before-slot':
          this.$slots.beforeLabel,
        'mc-accordion__header__label--with-after-slot': this.$slots.afterLabel,
      };
    },
  },
  methods: {
    onTrigger(checked) {
      /**
       * Emits the state value of the "open" prop
       */
      this.$emit('update:open', checked);
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.accordion';

.mc-accordion {
  position: relative;

  &__header {
    display: flex;
    align-items: center;

    &__label {
      align-items: center;
      display: flex;
      cursor: pointer;
      gap: $mu050;
      white-space: nowrap;

      &--with-before-slot {
        padding-left: $mu100;
      }

      &--with-after-slot {
        padding-right: $mu100;
      }
    }
  }
}
</style>
