<template>
  <div
    v-if="user"
    class="mc-sidebar-user"
    :class="{ 'is-open': open, 'is-close': !open }"
    @click="toggle"
  >
    <button
      :aria-expanded="open && isExpanded"
      class="mc-sidebar-user__header"
      aria-controls="user-content"
    >
      <span class="mc-sidebar-user__header__picture">
        <img
          v-if="user.imageBase64"
          class="base64"
          style="display: block; width: auto; height: 48px; max-width: 48px"
          aria-hidden
          alt="User Picture"
          :src="`data:image/png;base64, ${user.imageBase64}`"
        />
        <svg v-else width="24px" height="24px" fill="white" viewBox="0 0 24 24">
          <path
            d="M12 12.76a5.38 5.38 0 115.38-5.38A5.39 5.39 0 0112 12.76zM12 4a3.38 3.38 0 103.38 3.38A3.39 3.39 0 0012 4zM16.48 13.58a1 1 0 00-.81.11 7.3 7.3 0 01-7.34 0 1 1 0 00-.81-.11l-2.81.87a1 1 0 00-.71 1V21a1 1 0 001 1h14a1 1 0 001-1v-5.59a1 1 0 00-.71-1zM18 20H6v-3.86l1.75-.53a9.36 9.36 0 008.5 0l1.75.53z"
          />
        </svg>
      </span>
      <div v-if="open" style="display: flex; width: 100%">
        <p class="mc-sidebar-user__header__information">
          <span class="mc-sidebar-user__header__information__identity">
            {{ user?.firstName || '' }}
            {{ user?.lastName || '' }}
          </span>
          <span
            v-if="user.title"
            class="mc-sidebar-user__header__information__title"
          >
            {{ capitalizeTheFirstLetterOfEachWord(user.title) }}
          </span>
        </p>
        <span
          v-if="userFeatures?.length"
          class="mc-sidebar-user__header__information__icon"
        >
          <svg
            v-if="open && !isExpanded"
            width="16px"
            height="16px"
            fill="white"
            viewBox="0 0 16 16"
          >
            <path
              d="M13 7H9V3a1 1 0 00-2 0v4H3a1 1 0 000 2h4v4a1 1 0 002 0V9h4a1 1 0 000-2z"
            />
          </svg>
          <svg
            v-else
            width="16px"
            height="16px"
            fill="white"
            viewBox="0 0 16 16"
          >
            <path d="M12 9H4a1 1 0 010-2h8a1 1 0 010 2z" />
          </svg>
        </span>
      </div>
    </button>
    <ul
      v-if="userFeatures?.length"
      id="user-content"
      ref="content"
      :aria-hidden="!open || !isExpanded"
      class="mc-sidebar-user__content is-close"
    >
      <li
        v-for="(feature, i) in userFeatures"
        :key="i"
        @click.stop="featureClick(feature)"
      >
        <m-sidebar-feature
          :feature="feature"
          :open="open"
          :selected="selected"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import MSidebarFeature from './MSidebarFeature';

export default {
  name: 'MSidebarNavigationUser',
  components: { MSidebarFeature },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    userFeatures: {
      type: Array,
      default: () => [],
    },
    open: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['code-changed', 'toggle-sidebar'],
  data() {
    return {
      isExpanded: false,
    };
  },
  watch: {
    open() {
      this.updateContentCSS();
    },
    isExpanded() {
      this.updateContentCSS();
    },
  },
  methods: {
    toggle() {
      if (!this.open) {
        this.$emit('toggle-sidebar', true);
        this.isExpanded = true;
      } else {
        this.isExpanded = !this.isExpanded;
      }
    },
    capitalizeTheFirstLetterOfEachWord(words) {
      let separateWord = words.toLowerCase().split(' ');
      for (let i = 0; i < separateWord.length; i++) {
        separateWord[i] =
          separateWord[i].charAt(0).toUpperCase() +
          separateWord[i].substring(1);
      }
      return separateWord.join(' ');
    },
    featureClick(feature) {
      if (!feature.disabled) {
        this.$emit('code-changed', feature.code);
      }
    },
    updateContentCSS() {
      if (this.$refs.content) {
        this.$refs.content.style.height = '';
        this.$refs.content.style.transition = 'none';
        const startHeight = window.getComputedStyle(this.$refs.content).height;

        this.$refs.content.classList.add(
          this.open && this.isExpanded ? 'is-open' : 'is-close',
        );
        this.$refs.content.classList.remove(
          this.open && this.isExpanded ? 'is-close' : 'is-open',
        );

        const height = window.getComputedStyle(this.$refs.content).height;
        this.$refs.content.style.height = startHeight;

        // wait until the next frame so that everything has time to update before starting the transition
        requestAnimationFrame(() => {
          this.$refs.content.style.transition = '';
          requestAnimationFrame(() => {
            this.$refs.content.style.height = height;
          });
        });

        // Clear the saved height values after the transition
        const handler = () => {
          this.$refs.content.style.height = '';
          this.$refs.content.removeEventListener('transitionend', handler);
        };

        this.$refs.content.addEventListener('transitionend', handler);
      }
    },
  },
};
</script>

<style scopedl lang="scss">
$color-grey: #5b737d;
$selected-Link: #007f8c;

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

.mc-sidebar-user {
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: #264653;

  &__header {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: white;
    font-size: inherit;
    font-family: inherit;
    text-align: initial;
    margin: 0;
    width: 100%;
    overflow: hidden;

    &:focus-visible {
      box-shadow: none;
      border: none;
      border-radius: 12px;
      outline: 1px solid #0b96cc;
      outline-offset: -5px;
    }

    &:hover {
      cursor: pointer;

      .mc-sidebar-user__header__information__icon {
        background: #082435;
      }
    }

    &__picture {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-grey;
      border-radius: 24px;
      width: 48px;
      min-width: 48px;
      height: 48px;
      overflow: hidden;

      img {
        display: block;
      }
    }

    &__information {
      justify-content: space-around;
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0;
      margin-left: 8px;
      height: 40px;

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 4px;
      }

      &__identity {
        font-size: 16px;
        font-weight: bold;
      }

      &__title,
      &__identity {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 176px;
      }
    }
  }

  &__content {
    list-style-type: none;
    padding: 0;
    overflow: hidden;
    margin: -8px 0px 24px 0px;
    @include transition(all 300ms ease-in-out);

    &.is-close {
      height: 0;
      margin: 0;
    }

    .is-open {
      height: auto;
    }
  }

  &.is-close {
    .mc-sidebar-user__header {
      @include transition(padding 300ms ease-in-out);
      padding: 12px 8px;
      margin: 8px 0;
    }
  }

  &.is-open {
    .mc-sidebar-user__header {
      @include transition(padding 300ms ease-in-out);
      padding: 12px 24px 12px 32px;
      margin: 8px 0;
    }
  }
}
</style>
