<template>
  <div class="mc-taglist">
    <!-- @slot Use this slot to insert your own tag list -->
    <slot>
      <template v-if="items.length > 0">
        <MTag
          v-for="(tag, index) in items"
          v-show="!state.max || index < state.max"
          :key="`tag${index}`"
          v-bind="tag"
          size="s"
          :model-value="tag.type === 'selectable' && setTagValue(tag)"
          @update:model-value="
            (e) => tag.type === 'selectable' && onChange(e, tag.value ?? tag.id)
          "
          @remove-tag="onRemoveTag"
        />
        <MTag
          v-if="(state.max && items.length > state.max) || state.show"
          :id="`showhide${state.uuid}`"
          :label="getShowHideLabel"
          size="s"
          class="mc-taglist__showhide"
          @click="onShowHide"
        />
      </template>
    </slot>
  </div>
</template>

<script setup>
import { computed, reactive, watch } from 'vue';
import MTag from '../tags/MTag.vue';

const props = defineProps({
  /**
   * An array of objects to generate the list of tags. Each object can contain all the prop that can be passed to the **MTag** component.
   * @type { [{type: String, label: String, id: String | Number }]}
   */
  items: {
    type: Array,
    default: () => [],
  },
  /**
   * Defines the maximum number of visible tags
   */
  max: {
    type: Number,
    default: null,
  },
  /**
   * Label of the "Show/Hide" tag, when the tag list is reduced.
   */
  showLabel: {
    type: String,
    default: 'Show all',
  },
  /**
   * Label of the "Show/Hide" tag, when the tag list is fully visible.
   */
  reduceLabel: {
    type: String,
    default: 'Reduce',
  },
  /**
   * Allows you to manage the display of the complete list of tags outside the component
   */
  stateless: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to manage the selected values with v-model
   * @ignore
   */
  modelValue: {
    type: Array,
    default: undefined,
  },
});

const emit = defineEmits([
  'remove-tag',
  'click:show-all',
  'show-all',
  'reduce',
  'update:modelValue',
]);

const state = reactive({
  uuid: Math.random(),
  max: props.max,
  show: false,
  value: props.modelValue ?? [],
});

/* watch */
watch(
  () => props.modelValue,
  (value) => {
    state.value = value;
  },
);

/* computed */
const getShowHideLabel = computed(() => {
  return state.show & !props.stateless ? props.reduceLabel : props.showLabel;
});

/* methods */
function onRemoveTag(id) {
  /**
   * Triggered when clicking on the "Delete" button of a `removable` type tag
   *
   * @event remove-tag
   */
  emit('remove-tag', id);
}

function onShowHide() {
  state.show = !state.show;

  if (props.stateless) {
    // TODO: Plan to delete the "show-all" & "reduce" events, which are not very useful
    const eventName = state.show ? 'show-all' : 'reduce';
    emit(eventName, props.items);

    /**
     * Triggered each time the "Show All" tag is clicked in stateless mode.
     */
    emit('click:show-all', props.items);

    return;
  }

  if (state.max) {
    state.max = state.show ? props.items.length : props.max;
  }
}

function onChange(checked, val) {
  let values = state.value;

  if (checked && !values.includes(val)) {
    values.push(val);
  } else {
    values = values.filter((key) => key !== val);
  }

  /**
   * Triggered each time a selectable tag is checked/unchecked. _(can be used with a v-model)_
   */
  emit('update:modelValue', values);
  state.value = values;
}

function setTagValue(tag) {
  return props.modelValue
    ? props.modelValue.includes(tag.value ?? tag.id)
    : undefined;
}
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
.mc-taglist {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: $mu100;

  &__showhide {
    cursor: pointer;
  }
}
</style>
