import MRadio from './MRadio.vue';
import MRadioGroup from './MRadioGroup.vue';

MRadio.install = (app) => {
  app.component('MRadio', MRadio);
};

MRadioGroup.install = (app) => {
  app.component('MRadioGroup', MRadioGroup);
};

export { MRadio, MRadioGroup };
