<template>
  <div
    v-if="$slots.edition || $slots.actions || $slots.filters"
    class="mc-datatable__header"
  >
    <div class="mc-datatable__topbar">
      <div v-if="$slots.edition" class="mc-datatable__topbar-edition">
        <slot name="edition" />
      </div>
      <div v-if="$slots.actions" class="mc-datatable__topbar-actions">
        <slot name="actions" />
      </div>
    </div>
    <div v-if="$slots.filters" class="mc-datatable__filters">
      <slot name="filters" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MDataTableTop',
});
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.datatable-header';

.mc-datatable {
  &__topbar-actions {
    margin-left: auto;
  }
}
</style>
