<template>
  <div v-if="hasLabel" class="mc-checkbox" :class="rootClass">
    <input
      v-bind="$attrs"
      :id="id"
      ref="input"
      type="checkbox"
      class="mc-checkbox__input"
      :class="classObjectInput"
      :checked="modelValue"
      @change="onChange"
    />
    <label :for="id" class="mc-checkbox__label">
      <!-- @slot Use this slot if you want to customize the label display -->
      <slot name="label">
        {{ label }}
      </slot>
    </label>
  </div>

  <input
    v-else
    v-bind="$attrs"
    :id="id"
    ref="input"
    type="checkbox"
    class="mc-checkbox__input"
    :class="classObjectInput"
    :checked="modelValue"
    @change="onChange"
  />
</template>

<script>
/**
 * > Checkboxes are used to select one or multiple options in a list. They usually find their place in forms and are also used to accept some mentions.
 *
 * The `MCheckbox` component is the **Vue.js** implementation of the **Checkbox** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Form/Checkbox/).
 */
export default {
  name: 'MCheckbox',
  inheritAttrs: false,
  props: {
    /**
     * The value of the `id` attribute of the input element
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * The label of the Input Radio
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * The value of the `checked` attribute of the input element. _(Can be used with `v-model`)_
     */
    modelValue: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to define the component as indeterminate
     */
    indeterminate: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to define the component as invalid
     */
    isInvalid: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to place a CSS class on the root element of the component
     */
    rootClass: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    hasLabel() {
      return this.label != null || this.$slots.label != null;
    },
    classObjectInput() {
      return {
        'is-invalid': this.isInvalid,
      };
    },
  },
  watch: {
    indeterminate() {
      this.setIndeterminate();
    },
  },
  mounted() {
    this.setIndeterminate();
  },
  methods: {
    setIndeterminate() {
      this.$refs.input.indeterminate = this.indeterminate;
    },
    onChange(event) {
      /**
       * Emits the state value of the "checked" state
       */
      this.$emit('update:modelValue', event.target.checked);
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.checkbox';
</style>
