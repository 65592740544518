<template>
  <div class="mc-sidebar-sub-feature" :aria-label="feature?.label">
    <button class="mc-sidebar-sub-feature__trigger" @click="handleBackClick">
      <span class="mc-sidebar-sub-feature__container">
        <svg width="24px" height="24px" viewBox="0 0 24 24">
          <path
            d="M9 19a1 1 0 01-.71-.29l-6-6a1 1 0 010-1.42l6-6a1 1 0 111.42 1.42L4.41 12l5.3 5.29a1 1 0 010 1.42A1 1 0 019 19z"
          />
          <path d="M21 13H3.24a1 1 0 010-2H21a1 1 0 010 2z" />
        </svg>
        <span class="mc-sidebar-sub-feature__trigger__label">
          {{ feature?.label }}
        </span>
      </span>
    </button>
    <ul v-if="feature?.items?.length">
      <li
        v-for="(subFeature, i) in feature.items"
        :key="i"
        class="mc-sidebar-sub-feature__item"
        @click.stop="featureSelected(subFeature.code)"
      >
        <m-sidebar-feature
          :feature="subFeature"
          :open="open"
          :selected="selected"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import MSidebarFeature from './MSidebarFeature';

export default {
  name: 'MSidebarSubFeatures',
  components: { MSidebarFeature },
  props: {
    feature: {
      type: Object,
      default: () => ({}),
    },
    open: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['back-click', 'code-changed'],
  methods: {
    handleBackClick() {
      this.$emit('back-click', this.feature);
    },
    featureSelected(code) {
      this.$emit('code-changed', code);
    },
  },
};
</script>

<style scoped lang="scss">
$color-grey: #5b737d;
$selected-Link: #007f8c;

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

.mc-sidebar-sub-feature {
  width: 320px;

  &__trigger {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    margin: 0;
    font-size: inherit;
    font-family: inherit;
    width: 100%;
    padding: 0px 32px;

    &__label {
      font-size: 16px;
      font-weight: 700;
      margin-left: 8px;
      color: white;
    }

    &:focus-visible {
      box-shadow: none;
      border: none;
      outline: none;

      .mc-sidebar-sub-feature__container {
        box-shadow: none;
        border: none;
        border-radius: 6px;
        outline: 1px solid #0b96cc;
      }
    }
  }

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 24px;
    padding: 8px 0px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
</style>
