<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MDataTableColumn',
  render: () => null,
});
</script>

<script setup>
defineProps({
  value: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  sortable: {
    type: Boolean,
    default: false,
  },
  sortDirectionDefault: {
    type: String,
    default: null,
    validator: (value) => ['ASC', 'DESC'].includes(value),
  },
  additionnalProps: {
    type: Object,
    default: null,
  },
  class: {
    type: String,
    default: '',
  },
  e2eSelector: {
    type: String,
    default: '',
  },
});
defineEmits(['update:sortDirection']);
</script>
