<template>
  <div class="mc-segmented-control" :class="classObject">
    <button
      v-for="(segment, index) in segments"
      :key="`segment${index}`"
      type="button"
      size="s"
      class="mc-segmented-control__segment"
      :class="classObjectSegment(segment, index)"
      @click="onElementClicked($event, segment, index)"
    >
      <span class="mc-segmented-control__content">
        <MIcon
          v-if="activeIndex === index"
          name="NotificationAvailable16"
          class="mc-segmented-control__icon"
        />
        <span class="mc-segmented-control__label">
          {{ segment.label }}
        </span>
      </span>
    </button>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import MIcon from '../icon/MIcon.vue';

const props = defineProps({
  /**
   * An array of objects that allows you to provide all the data needed to generate the content for each Segment
   * @type {[{ label: String }]}
   */
  segments: {
    type: Array,
    default: () => [],
  },
  /**
   * Allows to define the size of the component
   * @values s, m
   */
  size: {
    type: String,
    default: 's',
    validator: (value) => ['s', 'm'].includes(value),
  },
  /**
   * Allows to set the active item. _(Should be a `ref()`)_
   * @ignore
   */
  modelValue: {
    type: Number,
    default: null,
  },
});

/** emits */
const emit = defineEmits(['update:modelValue', 'click:segment']);

/** data */
const activeIndex = ref(0);

/** computed */
const classObject = computed(() => {
  return {
    [`mc-segmented-control--${props.size}`]: props.size,
  };
});

/** watch */
watch(
  () => props.modelValue,
  (value) => {
    if (value || value === 0) {
      activeIndex.value = value;
    }
  },
  { immediate: true }
);

/** methods */
const classObjectSegment = (item, index) => {
  return [
    item.class,
    {
      'is-active': activeIndex.value === index,
      'is-disabled': item.disabled,
    },
  ];
};

const onElementClicked = (event, item, itemIndex) => {
  if (item?.disabled) {
    event.preventDefault();
    return;
  }

  if (itemIndex !== activeIndex.value) {
    activeIndex.value = itemIndex;
    /**
     * Triggers when the value of the selected item index changed
     *
     * - @arg {number} [item] - value of the selected item index
     */
    emit('update:modelValue', activeIndex.value);
  }

  if (event) {
    /**
     * Triggers when a item is clicked.
     *
     * - @arg {object} [item] - a js object of values & datas of the item clicked
     * // TODO: Refactoring event naming
     */
    emit('click:segment', { index: itemIndex, ...item });
  }
};
</script>

<style lang="scss" scoped>
@import 'settings-tools/all-settings';
@import 'components/c.segmented-control'
</style>
