<template>
  <div v-if="features.length" class="mc-sidebar-section">
    <ul
      :class="{
        'is-hidden': expandedSubGroup && open,
        'is-visible': !expandedSubGroup || !open,
      }"
      :aria-hidden="expandedSubGroup && open"
    >
      <li
        v-for="(data, i) in features"
        :key="i"
        @click="handleGroupClick(data)"
      >
        <MSidebarFeatureGroup
          :feature-group="data"
          :expand="expandedGroup === data.code"
          :open="open"
          :with-tooltip="withTooltip"
          :selected="selected"
          @feature-click="handleFeatureClick"
          @code-changed="codeChanged"
        />
      </li>
    </ul>
    <MSidebarSubFeatures
      v-if="expandedSubGroup && open"
      :feature="expandedSubGroup"
      :open="open"
      :selected="selected"
      @code-changed="codeChanged"
      @back-click="handleFeatureClick"
    />
  </div>
</template>

<script>
import MSidebarFeatureGroup from './MSidebarFeatureGroup.vue';
import MSidebarSubFeatures from './MSidebarSubFeatures.vue';

export default {
  name: 'MSidebarSection',
  components: { MSidebarSubFeatures, MSidebarFeatureGroup },
  props: {
    features: {
      type: Array,
      default: () => [],
    },
    withTooltip: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['code-changed', 'toggleSidebar'],
  data() {
    return {
      expandedGroup: undefined,
      expandedSubGroup: undefined,
    };
  },
  methods: {
    handleGroupClick(data) {
      const open = this.open;
      if (!open && data.items?.length) {
        this.$emit('toggleSidebar');
      }

      if (open && this.expandedGroup === data.code) {
        this.expandedGroup = undefined;
      } else {
        this.expandedGroup = data.code;
      }
    },
    handleFeatureClick(feature) {
      if (feature) {
        if (
          this.expandedSubGroup &&
          this.expandedSubGroup.code === feature.code
        ) {
          this.expandedSubGroup = undefined;
        } else {
          this.expandedSubGroup = feature;
        }
      } else {
        this.expandedSubGroup = undefined;
      }
    },
    codeChanged(code) {
      this.$emit('code-changed', code);
    },
  },
};
</script>

<style scoped lang="scss">
$color-grey: #5b737d;
$selected-Link: #007f8c;

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

.mc-sidebar-section {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  min-height: 38px;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  padding: 1px 0px;
  scrollbar-color: #009eac #082435;
  scrollbar-width: thin;

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
  }

  ul {
    align-items: start;
    flex-direction: column;
    justify-content: center;

    li {
      flex-direction: column;
      box-sizing: border-box;
      margin: 4px 0px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.is-hidden {
      @include transition(margin-left 300ms ease-in-out);
      margin-left: -320px;
      opacity: 0;
    }

    &.is-visible {
      @include transition(margin-left 300ms ease-in-out);
      margin-left: 0;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    background: #082435;

    &-thumb {
      background: #009eac;
    }
  }
}
</style>
