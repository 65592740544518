<template>
  <div class="mc-hero" :class="classObject">
    <div :class="{ 'ml-container': true, 'ml-container--fluid': !fixed }">
      <div class="ml-flexy ml-flexy--gutter">
        <div class="mc-hero__main ml-flexy__col">
          <picture class="mc-hero__cover">
            <source
              v-for="(src, index) in srcSet"
              :key="index"
              :srcset="src.url"
              :media="`(max-width: ${src.width}px)`"
            />
            <img class="mc-hero__visual" :src="imgSrc" :alt="imgAlt" />
          </picture>
          <div class="mc-hero__header">
            <MHeading
              class="mc-hero__title"
              :class="{ 'mt-heading--center': center }"
              :align="getAlign"
              :level="getHeadingLevel"
              size="s"
              underline
              lightest
            >
              {{ title }}
            </MHeading>
          </div>
          <div v-if="$slots.default" class="mc-hero__content mt-body-m">
            <!-- @slot Use this slot to insert the main content of the Hero -->
            <slot />
          </div>
        </div>
        <div v-if="$slots.boxed" class="mc-hero__boxed ml-flexy__col">
          <!-- @slot Use this slot to insert the boxed content of the Hero -->
          <slot name="boxed" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MHeading from '../heading/MHeading.vue';

/**
 * > A hero is an element that you can use on the top of your pages to add a visual or draw the attention of the user on a specific message.
 *
 * The `MHero` component is the **Vue.js** implementation of the **Hero** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Hero/).
 */
export default {
  name: 'MHero',
  components: {
    MHeading,
  },
  props: {
    /**
     * The textual content of the Hero title
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * The value of the `src` attribute of the background image
     */
    imgSrc: {
      type: String,
      required: true,
    },
    /**
     * Allows to define the HTML tag to be used for the Hero title
     * @values h1, h2, h3, h4
     */
    titleTag: {
      type: String,
      default: 'h1',
      validator: (value) => ['h1', 'h2', 'h3', 'h4'].includes(value),
    },
    /**
     * The value of the `alt` attribute of the background image
     */
    imgAlt: {
      type: String,
      default: '',
    },
    /**
     * An array of objects allows to load multiple image sources for different display/device scenarios.
     */
    srcSet: {
      type: Array,
      default: null,
    },
    /**
     * Allows the container to be made "fixed"
     * @see [About Hero Container](https://mozaic.adeo.cloud/Components/Hero/code/#the-container)
     */
    fixed: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows the entire contents of the Hero to be centred
     */
    center: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classObject() {
      return {
        'mc-hero--center': this.center,
        'mc-hero--with-content': this.$slots.default,
        'mc-hero--with-boxed': this.$slots.boxed,
      };
    },
    getHeadingLevel() {
      return this.titleTag.slice(1);
    },
    getAlign() {
      return this.center ? 'center' : 'left';
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'typography/t.all-base-typography';
@import 'layouts/l.container';
@import 'layouts/l.flexy';
@import 'components/c.hero';
</style>
