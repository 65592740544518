<template>
  <div>
    <div class="mc-overlay-loader" :class="{ 'is-visible': isVisible }">
      <MLoader size="l" theme="light" :text="text" />
    </div>
  </div>
</template>

<script>
import MLoader from '../loader/MLoader.vue';

/**
 * The `MOverlayLoader` component is the **Vue.js** implementation of the **Overlay loader** component of Mozaic Design System.
 */
export default {
  name: 'MOverlayLoader',
  components: {
    MLoader,
  },
  props: {
    /**
     * Define if the overlay is visible or not
     */
    isVisible: {
      type: Boolean,
      default: false,
    },
    /**
     * Loader text
     */
    text: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.overlay';
</style>
