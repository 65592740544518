<template>
  <span
    role="separator"
    class="mc-sidebar-separator"
    :class="{'is-open': open, 'is-close': !open}"/>
</template>

<script>
export default {
  name: 'MSidebarSeparator',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">

$color-grey: #5B737D;
$selected-Link: #007F8C;

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

.mc-sidebar-separator {
  border-bottom: 1px solid $color-grey;
  display: flex;

  &.is-close {
    @include transition(margin 300ms ease-in-out);
    margin: 24px 0;
  }

  &.is-open {
    @include transition(margin 300ms ease-in-out);
    margin: 24px 32px;
  }
}

</style>
