<template>
  <div
    class="mc-datatable"
    :class="{ 'mc-datatable--sticky-header': fixedHeader }"
  >
    <slot name="topbar"></slot>

    <slot name="selectionbar">
      <MDataTableSelectionBar
        v-if="selectionbar && multiSelectionPage"
        :select-all="getSelectAll"
        :label-page-selected="getLabelSelectedPage"
        :label-all-items-selected="getLabelAllItemsSelected"
        :label-button-select-all="getLabelButtonSelectAll"
        :label-button-clear-selection="labelButtonClearSelection"
        @select-all="state.selectAll = true"
        @clear-all="clearAll"
      />
    </slot>

    <div class="mc-datatable__container">
      <!-- MAIN -->
      <div class="mc-datatable__main" :style="{ 'max-height': maxHeight }">
        <table class="mc-datatable__table">
          <thead>
            <tr>
              <slot name="headers">
                <th
                  v-if="selectable"
                  scope="col"
                  class="mc-datatable__cell-checkbox"
                >
                  <div class="mc-checkbox">
                    <input
                      id="masterCheckbox"
                      v-model="state.pageState"
                      type="checkbox"
                      class="mc-checkbox__input"
                      aria-label="Select all rows"
                      :indeterminate="isPageIndeterminate"
                      :disabled="!items.length"
                      @change="onClickMasterCheckbox"
                    />
                  </div>
                </th>
                <th
                  v-if="expandable"
                  scope="col"
                  class="mc-datatable__cell-button"
                >
                  &nbsp;
                </th>
                <MDataTableHeaderV2
                  v-for="(header, index) in getHeaders"
                  :key="`header-${index}`"
                  v-bind="{
                    ...header,
                    sortDirection: getSortDirection(header.value),
                    additionnalProps: header.additionnalProps,
                  }"
                  @update:sort-direction="updateSort"
                >
                  <slot :name="`header.${header.value}`" :header="header" />
                </MDataTableHeaderV2>
              </slot>
            </tr>
          </thead>
          <tbody>
            <slot name="body">
              <template v-if="!props.loading">
                <MDataTableRow
                  v-for="(item, index) in props.items"
                  :key="item[props.indexKey]"
                  :item="item"
                  :row-index="index"
                  :headers="getHeaders"
                  :index-key="indexKey"
                  :clickable-rows="clickableRows"
                  :selectable="selectable"
                  :expandable="expandable"
                  :expandable-list="expandableList"
                  :show-expandable-content="showExpandableContent"
                  :expander-accessible-label="expanderAccessibleLabel"
                  @expand-row="onExpandRow"
                  @retract-row="onRetractRow"
                >
                  <template #checkbox>
                    <th v-if="selectable" scope="row">
                      <div class="mc-checkbox">
                        <input
                          v-model="state.selectedItems"
                          type="checkbox"
                          class="mc-checkbox__input"
                          :aria-label="`Select rows n°${index}`"
                          :value="item"
                          @change="onSelectRow($event, item, index)"
                        />
                      </div>
                    </th>
                  </template>

                  <template #cells>
                    <td
                      v-for="(header, headerIndex) in getHeaders"
                      :key="`${index}-${item[props.indexKey]}-${header.value}`"
                      :class="header.class"
                      @click="clickableRows && onClickRow(item, headerIndex)"
                    >
                      <slot
                        :name="`cell.${header.value}`"
                        :item="item"
                        :row-index="index"
                      >
                        {{ item[header.value] }}
                      </slot>
                    </td>
                  </template>

                  <!-- expand content -->
                  <template #expandContent="expandedPros">
                    <slot name="expandContent" v-bind="expandedPros"></slot>
                  </template>
                </MDataTableRow>
              </template>

              <!-- LOADING -->
              <template v-else>
                <tr v-for="n in state.itemsPerPage" :key="n">
                  <th v-if="selectable" scope="row">
                    <span class="skeleton-cell">&nbsp;</span>
                  </th>
                  <td
                    v-for="(header, index) in getHeaders"
                    :key="`skeleton-cell-${index}`"
                  >
                    <span class="skeleton-cell">&nbsp;</span>
                  </td>
                </tr>
              </template>
            </slot>

            <!-- NO-DATA -->
            <tr
              v-if="props.items.length === 0 && !loading"
              class="mc-datatable__empty"
            >
              <td :colspan="getColspan" class="mc-datatable__empty-cell">
                <div class="mc-datatable__empty-content">
                  <slot name="no-data" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- FOOTER -->
      <div v-if="props.pageable" class="mc-datatable__footer">
        <!-- DataTable Rows Per Page Selector -->
        <MDataTableRowsPerPage
          :label="props.itemsPerPageLabel"
          :model-value="getItemsPerPage"
          :loading="props.loading"
          :options="getItemsPerPageOptions"
          @update:model-value="updatePageSize($event)"
        />

        <!-- DataTable Info (count) -->
        <MDataTableInfo
          v-if="!props.hidePageCount"
          :label="props.countLabel"
          :label-items="props.infoLabelItems"
          :start="getPageCountInfos.start"
          :end="getPageCountInfos.end"
          :total="getPageCountInfos.total"
        />

        <!-- DataTable Pagination -->
        <div class="mc-datatable__pagination">
          <MPagination
            :value="getCurrentPage"
            :length="getPaginationLength"
            :disabled="props.loading"
            :light="props.disablePageListing"
            :show-partial="props.showPartialPages"
            @update:value="updateCurrentPage"
          >
            <template #text="{ option }">
              <slot name="paging.text" :option="option">
                {{ option.text }}
              </slot>
            </template>
          </MPagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  reactive,
  useSlots,
  defineProps,
  onMounted,
  watch,
} from 'vue';

const defaultPageSizes = [7, 10, 25, 50, 100];
export default defineComponent({ name: 'MDataTableV2' });
</script>

<script setup>
import MPagination from '../pagination/MPagination.vue';
import MDataTableHeaderV2 from './MDataTableHeaderV2.vue';
import MDataTableRow from './MDataTableRow.vue';
import MDataTableInfo from './MDataTableInfo.vue';
import MDataTableRowsPerPage from './MDataTableRowsPerPage.vue';
import MDataTableSelectionBar from './MDataTableSelectionBar.vue';

const props = defineProps({
  /**
   * An array of objects representing the data to be displayed.
   */
  items: {
    type: Array,
    required: true,
  },
  /**
   * An array of objects used to define the DataTable headers.
   * This property is mandatory if you are not using the `<MDataTableColumn />` component to define your headers.
   */
  headers: {
    type: Array,
    default: undefined,
  },
  /**
   * Displays the loading state to indicate data load is in progress
   */
  loading: {
    type: Boolean,
    default: undefined,
  },
  /**
   * // TODO: To be defined
   * @ignore
   */
  indexKey: {
    type: String,
    default: 'id',
  },
  // Pagination
  /**
   * Displays the pagination du DataTable _(and the component footer as a whole)_.
   * This prop must be defined with the `totalItems` prop.
   */
  pageable: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to define the value of the Select "Rows per page" when the DataTable is initialised.
   * This value must be one of the values in the `itemsPerPageOptions` array.
   */
  itemsPerPage: {
    type: Number,
    default: undefined,
  },
  /**
   * Used to set the desired values for the Select "Rows per page".
   * @type {[7, 10, 25, 50, 100]}
   */
  itemsPerPageOptions: {
    type: Array,
    default: () => defaultPageSizes,
  },
  /**
   * TODO: To remove
   * @deprecated
   * @ignore
   */
  disablePageListing: {
    type: Boolean,
    default: false,
  },
  /**
   * Sets the default value for the Select "Pagination".
   * The options displayed in Select "Pagination" are calculated on the basis of the values in props `totalItems` & `itemsPerPage`.
   */
  currentPage: {
    type: Number,
    default: undefined,
  },
  /**
   * Used to indicate the total number of data items.
   * This property must be defined as soon as the `pageable` property is defined.
   */
  totalItems: {
    type: Number,
    default: undefined,
  },
  /**
   * Used to define the value of the label associated with the Select "Rows per page".
   */
  itemsPerPageLabel: {
    type: String,
    default: 'Rows per page',
  },
  /**
   * Allow to replaces the preposition "of" in the footer sentence _(for example)_: "15-21 of 50 items".
   */
  countLabel: {
    type: String,
    default: 'of',
  },
  /**
   * Allow to replaces the text "items" in the footer sentence _(for example)_: "15-21 of 50 items".
   */
  infoLabelItems: {
    type: String,
    default: 'items',
  },
  /**
   * Allows you to display only the first 3 & last 3 options in the pagination
   */
  showPartialPages: {
    type: Boolean,
    default: false,
  },
  // Sort
  /**
   * Sort
   */
  sort: {
    type: Object,
    default: undefined,
  },
  // Display Options
  /**
   * Allows you to change the line size
   */
  size: {
    type: String,
    default: 'm',
    validator: (value) => ['s', 'm', 'l'].includes(value),
  },
  /**
   * Hide "XX - YY of ZZ items" indication in footer
   */
  hidePageCount: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to define a maximum height for the DataTable
   */
  maxHeight: {
    type: String,
    default: undefined,
  },
  /**
   * Allows you to make the header fixed.
   * This property works in conjunction with the `maxHeight`
   */
  fixedHeader: {
    type: Boolean,
    default: false,
  },
  // Selection
  selectable: {
    type: Boolean,
    default: false,
  },
  /**
   * Displays or hides the selection bar
   */
  selectionbar: {
    type: Boolean,
    default: false,
  },
  /** @ignore */
  selection: {
    type: Array,
    default: () => [],
  },
  /** @ignore */
  labelButtonClearSelection: {
    type: String,
    default: 'Clear selection',
  },
  /** @ignore */
  selectPage: {
    type: Boolean,
    default: false,
  },
  /** @ignore */
  labelPageSelected: {
    type: String,
    default: undefined,
  },
  /** @ignore */
  labelAllItemsSelected: {
    type: String,
    default: undefined,
  },
  /** @ignore */
  selectAll: {
    type: Boolean,
    default: undefined,
  },
  /** @ignore */
  labelButtonSelectAll: {
    type: String,
    default: undefined,
  },
  /** @ignore */
  clickableRows: {
    type: Boolean,
    default: false,
  },
  // Expansion
  /**
   * Allows all the rows in the DataTable to be expandable
   */
  expandable: {
    type: Boolean,
    default: false,
  },
  /**
   * Sets the default text for the "fold/unfold" buttons. Useful for enhancing accessibility
   */
  expanderAccessibleLabel: {
    type: String,
    default: undefined,
  },
  /**
   * Allows you to choose the lines on which to insert "expandable" content
   */
  expandableList: {
    type: Array,
    default: undefined,
  },
  /**
   * Open expandable content by default
   */
  showExpandableContent: {
    type: Boolean,
    default: false,
  },

  // Misc
  e2eSelector: {
    type: String,
    default: '',
  },
});

const emit = defineEmits([
  'update:sort',
  'update:currentPage',
  'update:itemsPerPage',
  'change',
  'click-row',
  'select-row',
  'unselect-row',
  'select-all-rows',
  'unselect-all-rows',
  'update:selectPage',
  'update:selectAll',
  'update:selection',
  'expand-row',
  'retract-row',
]);

const slots = useSlots();

const defaultPageSizeOptions = defaultPageSizes.map((value) => ({
  text: String(value),
  value: String(value),
}));

const state = reactive({
  sort: undefined,
  itemsPerPageOptions: defaultPageSizeOptions,
  itemsPerPage: 7,
  currentPage: 1,
  isExpand: false,
  selectAll: false,
  selectPage: false,
  pageState: false,
  selectedItems: props.selection,
});

/** Initialisation */
onMounted(() => {
  if (props.pageable && isNaN(Number(props.totalItems))) {
    throw new Error('MDataTable: totalItems is required when pageable is true');
  }

  if (isPageSelected.value && isPageSelected.value) {
    state.pageState = isPageSelected.value;
  }
  const headersConfig = getHeaders.value;

  headersConfig?.forEach(
    ({ value, sortDirectionDefault, additionnalProps }) => {
      if (sortDirectionDefault) {
        if (getSort.value && sortDirectionDefault) {
          throw new Error(
            'MDataTable: sortDirectionDefault can be set only once per header',
          );
        }

        updateSort(
          {
            column: value,
            direction: sortDirectionDefault,
            ...additionnalProps,
          },
          true,
        );
      }
    },
  );
});

/** Computed */
const getPageCountInfos = computed(() => {
  const {
    itemsPerPage = state.itemsPerPage,
    currentPage = state.currentPage,
    totalItems = 0,
  } = props;

  const itemsOnCurrentPage = currentPage * itemsPerPage;

  return {
    start: itemsOnCurrentPage - itemsPerPage + 1,
    end: Math.min(itemsOnCurrentPage, totalItems),
    total: totalItems,
  };
});

const getPaginationLength = computed(
  () => Math.ceil(Number(props.totalItems) / getItemsPerPage.value) || 1,
);

const getHeaders = computed(
  () => getDatatableHeadersConfigChildrens.value || props.headers,
);

const getPageState = computed({
  get() {
    return state.pageState;
  },
  set(newValue) {
    state.pageState = newValue;
    emit('update:selectPage', newValue);
  },
});

const multiSelectionPage = computed(() => {
  return getSelectedItems.value.length >= props.items.length;
});

const getSelectedItems = computed({
  get() {
    return state.selectedItems || props.selection;
  },
  set(newValue) {
    emit('update:selection', newValue);
  },
});

const getLabelButtonSelectAll = computed(() => {
  return (
    props.labelButtonSelectAll ??
    `Select all ${props.totalItems} rows of this table`
  );
});

const getLabelSelectedPage = computed(() => {
  return (
    props.labelPageSelected ??
    `${getSelectedItems.value.length} rows on this table are selected`
  );
});

const getLabelAllItemsSelected = computed(() => {
  return (
    props.labelAllItemsSelected ??
    `All ${props.totalItems} rows in the table are selected`
  );
});

const isPageSelected = computed(() => {
  const itemsState =
    props.items.length &&
    props.items.every((item) => {
      return state.selectedItems.includes(item);
    });

  return itemsState;
});

const isPageIndeterminate = computed(() => {
  const itemsState = state.selectedItems.some((item) => {
    return props.items.includes(item);
  });

  return itemsState && !isPageSelected.value;
});

const getSelectAll = computed(() => {
  return props.selectAll || state.selectAll;
});

const getColspan = computed(() => {
  return (
    getHeaders.value.length +
    Number(props.selectable) +
    Number(props.expandable)
  );
});

/**
 * Get headers config from default slot
 */

const getDatatableHeadersConfigChildrens = computed(
  () =>
    getHeadersFromSlotName('default', 'MDataTableColumn') ||
    getHeadersFromSlotName('headers', 'MDataTableHeaderV2', {}),
);

const getItemsPerPageOptions = computed(() => {
  if (!props.itemsPerPageOptions) {
    return state.itemsPerPageOptions;
  }
  if (
    props.itemsPerPageOptions[0] &&
    typeof props.itemsPerPageOptions[0] === 'number'
  ) {
    return props.itemsPerPageOptions.map((option) => {
      const value = option.toString();
      return { text: value, value };
    });
  }
  return props.itemsPerPageOptions;
});

const getItemsPerPage = computed(
  () => props.itemsPerPage || state.itemsPerPage,
);
const getSort = computed(() => props.sort || state.sort);
const getCurrentPage = computed(() => props.currentPage || state.currentPage);

const getSortDirection = (headerKey) => {
  const sort = props.sort || state.sort;
  if (!sort || headerKey !== sort.column) {
    return undefined;
  }
  return sort.direction;
};

/** Methods */
function updateSort(sort, noChange = false) {
  state.sort = sort;
  emit('update:sort', sort);
  if (!noChange) {
    emitChange({ sort });
  }
}

function updatePageSize(size) {
  state.itemsPerPage = size;
  emit('update:itemsPerPage', size);
  emitChange({ size });
}

function updateCurrentPage(page) {
  state.currentPage = page;

  emit('update:currentPage', page);
  emitChange({ page });
}

function emitChange({
  size = undefined,
  sort = undefined,
  page = undefined,
} = {}) {
  const payload = {
    sort: sort || getSort.value,
    size: size || getItemsPerPage.value,
    page: page || getCurrentPage.value,
  };
  if (getPaginationLength.value < state.currentPage) {
    state.currentPage = getPaginationLength.value;
    payload.page = state.currentPage;
  }
  emit('change', payload);
}

function getHeadersFromSlotName(slotName, componentName, params) {
  const slot = slots[slotName];
  const slotHeaders =
    slot && slot(params)?.filter(({ type }) => type.name === componentName);

  const config = slotHeaders?.map(({ props: itemProps }) => ({
    ...itemProps,
    sortDirection:
      (props.sort?.column === itemProps?.value && props.sort?.direction) ||
      itemProps?.defaultSortDirection,
  }));

  return config || null;
}

function onClickRow(rowData, rowIndex) {
  emit('click-row', { data: rowData, index: rowIndex });
}

function onSelectRow(event, rowData, rowIndex) {
  const checked = event.target.checked;
  const eventArg = { data: rowData, index: rowIndex };

  if (checked) {
    emit('select-row', eventArg);
  } else {
    emit('unselect-row', eventArg);
  }

  const eventName = event.target.checked ? 'select-row' : 'unselect-row';
}

function onPageSelected(checked) {
  if (checked) {
    props.items.forEach((item) => {
      if (!state.selectedItems.includes(item)) {
        state.selectedItems.push(item);
      }
    });

    emit('select-all-rows', { data: state.selectedItems });
  } else if (!checked && !isPageIndeterminate.value) {
    emit('unselect-all-rows');
  }
}

function clearSelection() {
  props.items.forEach((item) => {
    if (state.selectedItems.includes(item)) {
      const index = state.selectedItems.findIndex(
        (selected) => String(selected) === String(item),
      );

      state.selectedItems.splice(index);
    }
  });
}

function clearAll() {
  state.selectedItems = [];
  state.selectAll = false;
}

function onClickMasterCheckbox($event) {
  const checked = $event && $event.target.checked;

  onPageSelected(checked);

  if (!checked) {
    clearSelection();
  }
}

function onExpandRow(data) {
  emit('expand-row', data);
}

function onRetractRow(data) {
  emit('retract-row', data);
}

/* watch */
watch(isPageSelected, (value) => {
  getPageState.value = value;
});

watch(
  () => state.selectedItems,
  (value) => {
    emit('update:selection', value);
  },
);

watch(
  () => props.selection,
  (value) => {
    state.selectedItems = value;
  },
);

watch(
  () => props.items,
  () => {
    state.selectAll = false;
  },
);

watch(
  () => state.selectAll,
  (value) => {
    emit('update:selectAll', value);
  },
);

watch(
  () => props.selectAll,
  (value) => {
    state.selectAll = value;
  },
);
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.checkbox';
@import 'components/c.datatable';
@import 'components/c.datatable-tools';
@import 'components/c.datatable-footer';
@import 'components/c.datatable-subtable';

.mc-datatable {
  $parent: get-parent-selector(&);

  &__main {
    overflow-y: auto;
  }

  tbody tr {
    &#{$parent}__empty {
      &,
      &:hover {
        background-color: $color-datatable-container-background;
      }
    }
  }

  &__empty {
    &-content {
      @include set-font-scale('05', 'm'); // 16px / 22px

      align-items: center;
      color: $color-datatable-thead-font;
      display: flex;
      gap: $mu150; // =24px
      height: px-to-rem(300);
      justify-content: center;
      text-align: center;

      strong {
        @include set-font-scale('06', 'm'); // 18px / 24px
      }
    }
  }
}

// Skeleton
.skeleton-cell {
  background-color: #cdd4d8;
  border-radius: get-border-radius('m');
  display: inline-block;
  height: var(--skeleton-height, $mu150);
  overflow: hidden;
  position: relative;
  width: var(--skeleton-width, 100%);

  &::after {
    position: absolute;
    inset: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#ffffff, 0) 0,
      rgba(#ffffff, 0.2) 20%,
      rgba(#ffffff, 0.5) 60%,
      rgba(#ffffff, 0)
    );
    animation: skeleton-animation 2s infinite;
    content: '';
  }
}

@keyframes skeleton-animation {
  100% {
    transform: translateX(100%);
  }
}
</style>
