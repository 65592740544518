<template>
  <div class="mc-progressbar" :class="classObject">
    <div
      ref="indicator"
      class="mc-progressbar__indicator"
      role="progressbar"
      :style="`width: ${setProgressValue()}%;`"
      :aria-valuenow="value"
      :aria-valuemin="valueMin"
      :aria-valuemax="valueMax"
    >
      &nbsp;
    </div>
    <div v-if="percent" class="mc-progressbar__percentage">
      {{ setProgressValue() }}%
    </div>
  </div>
</template>

<script>
/**
 * > A progress bar allows you to focus the attention of the user with a visual clue on his progress in a particular journey.
 *
 * The `MProgress` component is the **Vue.js** implementation of the **Progress bar** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/ProgressBar/).
 */
export default {
  name: 'MProgress',
  props: {
    /**
     * The progress value
     */
    value: {
      type: Number,
      default: 0,
    },
    /**
     * The size of the progressbar
     * @values xs, s, m
     */
    size: {
      type: String,
      default: 'm',
      validator: (value) => ['xs', 's', 'm'].includes(value),
    },
    /**
     * The value of the HTML attribute `aria-valuemin`
     */
    valueMin: {
      type: Number,
      default: 0,
    },
    /**
     * The value of the HTML attribute `aria-valuemax`
     */
    valueMax: {
      type: Number,
      default: 100,
    },
    /**
     * Defines whether the component should use the primary colour of the brand
     */
    branded: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to explicitly show the percentage of the progress value
     */
    percent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  computed: {
    classObject() {
      return {
        'mc-progressbar--branded': this.branded,
        'mc-progressbar--percent': this.percent,
        'mc-progressbar--half':
          this.percent && this.currentValue >= this.valueMax / 2,
        [`mc-progressbar--${this.size}`]: this.size,
      };
    },
  },
  methods: {
    setProgressValue() {
      const initValue = this.value;

      if (initValue > this.valueMax) {
        /* eslint-disable no-console */
        console.error(
          // eslint-disable-next-line quotes
          "You can't provide a value that is greater than the valueMax property"
        );
        /* eslint-enable no-console */
        return this.currentValue;
      }

      this.currentValue = Math.round(initValue);

      return this.currentValue;
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.progressbar';
</style>
