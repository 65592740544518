<template>
  <div>
    <component
      :is="setComponent"
      v-bind="setAttributes(featureGroup)"
      class="mc-feature-group"
      :class="{ 'is-selected': isSelected, 'is-open': open, 'is-close': !open }"
      @click="featureGroup.items?.length ? null : featureClick(featureGroup)"
    >
      <span class="mc-feature-group__container">
        <MTooltip v-if="!open && withTooltip" :id="featureGroup.code" :text="featureGroup.label" position="right">
          <MIcon
            v-if="featureGroup.icon"
            :name="featureGroup.icon"
            class="mc-feature-group__icon"
            style="width: 24px; height: 24px; fill: white"
          />
        </MTooltip>
        <MIcon
          v-else-if="featureGroup.icon"
          :name="featureGroup.icon"
          class="mc-feature-group__icon"
          style="width: 24px; height: 24px; fill: white"
        />
        <div v-if="open" style="display: flex">
          <span ref="label" class="mc-feature-group__label">
            {{ featureGroup.label }}
          </span>
          <div
            v-if="$refs.label?.offsetHeight < $refs.label?.scrollHeight"
            aria-hidden="true"
            class="mc-feature-group__label__tooltip"
          >
            <div class="mc-feature-group__label__tooltip__pointer"></div>
            <span>{{ featureGroup.label }}</span>
          </div>
          <div v-if="featureGroup.items?.length">
            <svg
              v-if="!expand"
              width="16px"
              height="16px"
              fill="white"
              viewBox="0 0 16 16"
            >
              <path
                d="M13 7H9V3a1 1 0 00-2 0v4H3a1 1 0 000 2h4v4a1 1 0 002 0V9h4a1 1 0 000-2z"
              />
            </svg>
            <svg
              v-else
              width="16px"
              height="16px"
              fill="white"
              viewBox="0 0 16 16"
            >
              <path d="M12 9H4a1 1 0 010-2h8a1 1 0 010 2z" />
            </svg>
          </div>
        </div>
      </span>
    </component>
    <ul
      v-if="featureGroup.items"
      :id="`${featureGroup.code}-list`"
      ref="content"
      class="mc-feature-group__content"
      :class="{ 'is-open': open && expand, 'is-close': !open || !expand }"
      :aria-hidden="!open || !expand"
    >
      <li
        v-for="(feature, i) in featureGroup.items"
        :key="i"
        @click.stop="featureClick(feature)"
      >
        <MSidebarFeature :feature="feature" :open="open" :selected="selected" />
      </li>
    </ul>
  </div>
</template>

<script>
import MIcon from '../icon/MIcon.vue';
import MSidebarFeature from './MSidebarFeature.vue';
import MTooltip from '../tooltip/MTooltip';

export default {
  name: 'MSidebarFeatureGroup',
  components: {MTooltip, MSidebarFeature, MIcon },
  props: {
    featureGroup: {
      type: Object,
      default: () => ({}),
    },
    expand: {
      type: Boolean,
      default: false,
    },
    withTooltip: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['feature-click', 'code-changed'],
  data() {
    return {
      isSelected: false,
    };
  },
  computed: {
    setComponent() {
      const data = this.featureGroup;
      const isLink = !!data.href;
      const routerLink =
        data.route && !this.featureGroup.items ? 'router-link' : null;
      const defaultComponent =
        isLink && !this.featureGroup.items ? 'a' : 'button';

      return routerLink ?? defaultComponent;
    },
  },
  watch: {
    selected: {
      handler: function() {
        this.updateSelected();
      },
      deep: true
    },
    expand() {
      this.updateSelected();
      this.updateContentCSS();
    },
    open() {
      this.updateSelected();
      this.updateContentCSS();
    },
  },
  methods: {
    setAttributes(data) {
      const id = data.code;
      const href = data.href && !this.featureGroup.items ? data.href : null;
      const type = !data.href ? 'button' : null;
      const to = data.route && !this.featureGroup.items ? data.route : null;

      return {
        ...data.attrs,
        id,
        href,
        to,
        type,
        'aria-label': data.label,
        'aria-expanded': this.open && this.expand,
        'aria-controls': `${data.code}-list`,
      };
    },
    updateSelected() {
      this.isSelected =
        (this.selected?.lv0 &&
          this.featureGroup.code === this.selected?.lv0?.code &&
          (!this.selected.lv1 || !this.expand || !this.open)) ??
        false;
    },
    featureClick(feature) {
      if (!feature.disabled) {
        if (feature.items?.length) {
          this.$emit('feature-click', feature);
        } else {
          this.$emit('code-changed', feature.code);
        }
      }
    },
    updateContentCSS() {
      if (this.$refs.content) {
        this.$refs.content.style.height = '';
        this.$refs.content.style.transition = 'none';
        const startHeight = window.getComputedStyle(this.$refs.content).height;

        this.$refs.content.classList.add(
          this.open && this.expand ? 'is-open' : 'is-close'
        );
        this.$refs.content.classList.remove(
          this.open && this.expand ? 'is-close' : 'is-open'
        );

        const height = window.getComputedStyle(this.$refs.content).height;
        this.$refs.content.style.height = startHeight;

        // wait until the next frame so that everything has time to update before starting the transition
        requestAnimationFrame(() => {
          this.$refs.content.style.transition = '';
          requestAnimationFrame(() => {
            this.$refs.content.style.height = height;
          });
        });

        // Clear the saved height values after the transition
        const handler = () => {
          this.$refs.content.style.height = '';
          this.$refs.content.removeEventListener('transitionend', handler);
        };

        this.$refs.content.addEventListener('transitionend', handler);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'settings-tools/all-settings';

$color-grey: #5b737d;
$selected-Link: #007f8c;

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

.mc-feature-group {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  text-align: initial;
  margin: 0;
  padding: 0;
  min-height: 40px;
  overflow: hidden;
  text-decoration: none;

  &__container {
    display: flex;
    align-items: center;
    margin: 5px 0;
    padding: 8px 0px;
    @include transition(margin 300ms ease-in-out);
  }

  &__label {
    @include set-font-scale('04', 'm');

    margin: 0 8px;
    width: 200px;
    min-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    &__tooltip {
      opacity: 0;
      transition: 0s opacity;
      background-color: #5b737d;
      border: 1px solid white;
      border-radius: 4px;
      position: absolute;
      pointer-events: none;
      left: calc(100% - 16px);
      padding: 8px 16px;
      width: 188px;

      &__pointer {
        position: absolute;
        top: 50%;
        left: -8px;
        top: calc(50% - 6px);
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 8px solid white;

        & div {
          position: relative;
          top: -6px;
          left: 2px;
          width: 0;
          height: 0;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-right: 8px solid #5b737d;
        }
      }
    }

    &:hover + .mc-feature-group__label__tooltip {
      opacity: 1;
      transition-delay: 1s;
    }
  }

  &__content {
    overflow: hidden;
    @include transition(height 300ms ease-in-out);

    &.is-close {
      height: 0;
    }

    &.is-open {
      height: auto;
    }
  }

  &.is-selected {
    background: $selected-Link;
  }

  &.is-close {
    .mc-feature-group__container {
      margin: 0 20px;
      width: 24px;
    }
  }

  &.is-open {
    .mc-feature-group__container {
      margin: 0 32px;
    }
  }

  &:hover {
    background: #405d68;
  }

  &:focus-visible {
    box-shadow: none;
    border: none;
    outline: none;

    .mc-feature-group__container {
      box-shadow: none;
      border: none;
      border-radius: 6px;
      outline: 1px solid #0b96cc;
    }
  }
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
}

ul {
  align-items: start;
  flex-direction: column;
  justify-content: center;

  li {
    flex-direction: column;
    box-sizing: border-box;
  }
}

:deep .mc-tooltip-container {
  cursor: pointer !important;
}

</style>
