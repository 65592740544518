const modifierList = (modifiers) => modifiers.replace(' ', '').split(',');

export const responsiveModifierValidators = (
  modifiers,
  authorizedModifiers
) => {
  return modifierList(modifiers).every((modifier) =>
    authorizedModifiers.includes(modifier)
  );
};

export const responsiveModifiers = (baseClass, modifiers, outputClasses) => {
  modifierList(modifiers).forEach((classSuffix) => {
    outputClasses.push(`${baseClass}--${classSuffix}`);
  });
};
