<template>
  <component :is="htmlTag" class="mc-badge" :class="classObject">
    <!-- @slot Use this slot to insert the main content of the Badge (text only!) -->
    <slot />
  </component>
</template>

<script>
/**
 * > A badge indicates the status of an entity and can evolve at any time.
 *
 * The `MBadge` component is the **Vue.js** implementation of the **Badge** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Badge/).
 */
export default {
  name: 'MBadge',
  props: {
    /**
     * Allows to define the Badge type
     * @values information, success, warning, danger, neutral
     */
    type: {
      type: String,
      default: 'information',
      validator: (value) =>
        ['information', 'success', 'warning', 'danger', 'neutral'].includes(
          value
        ),
    },
    /**
     * Allows to define the HTML tag to be used for the Badge
     */
    htmlTag: {
      type: String,
      default: 'span',
    },
  },
  computed: {
    classObject() {
      return {
        [`mc-badge--${this.type}`]: this.type && this.type != 'information',
      };
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.badge';
</style>
