<template>
  <component :is="hn" class="mt-heading" :class="classObject">
    <!-- @slot Use this slot to insert the main text of the Heading -->
    <slot />
  </component>
</template>

<script>
/**
 * > The heading provides basic typographic styles for your page titles, also known as H1. Each heading has its own sizes depending on the breakpoints.
 *
 * The `MHeading` component is the **Vue.js** implementation of the **Heading** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Heading/).
 */
export default {
  name: 'MHeading',
  props: {
    /**
     * The level of the Hn tag to use. _(Allowed values: 1 to 4)_
     * @values 1, 2, 3, 4
     */
    level: {
      type: String,
      default: '1',
      validator: (value) => ['1', '2', '3', '4'].includes(value),
    },
    /**
     * The heading size
     * @values s, m, l
     * @see [Available sizes](https://mozaic.adeo.cloud/Components/Heading/code/#sizes)
     */
    size: {
      type: String,
      default: 'm',
      validator: (value) => ['s', 'm', 'l'].includes(value),
    },
    /**
     * The heading font-weight
     * @values light, regular, semi-bold
     * @see [Available font-weights](https://mozaic.adeo.cloud/Components/Heading/code/#font-weights)
     */
    weight: {
      type: String,
      default: 'regular',
      validator: (value) => ['light', 'regular', 'semi-bold'].includes(value),
    },
    /**
     * Allows whether or not to display the underline
     */
    underline: {
      type: Boolean,
      default: false,
    },
    /**
     * Define the underline color
     * @values primary-01-200, primary-02-600,  primary-02-200
     */
    underlineColor: {
      type: String,
      default: null,
      validator: (value) =>
        ['primary-01-200', 'primary-02-600', 'primary-02-200'].includes(value),
    },
    /**
     * Allows to use the lightest variation when using a Heading on dark backgrounds
     */
    lightest: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows you to define the alignment of the text and its underline
     * @values left, center, right
     */
    align: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'center', 'right'].includes(value),
    },
    /**
     * Allows to define the `line-height-xs`. Should only be used on headers that do not go to the line.
     */
    lineHeightXS: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hn() {
      return `h${this.level}`;
    },
    classObject() {
      return {
        'mt-heading--lightest': this.lightest,
        'mt-heading--underline': this.underline,
        'mt-heading--line-height-xs': this.lineHeightXS,
        [`mt-heading--${this.size}`]: this.size,
        [`mt-heading--${this.align}`]: this.align,
        [`mt-heading--${this.weight}`]:
          this.weight === 'light' || this.weight === 'semi-bold',
        [`mt-heading--line-${this.underlineColor}`]: this.underlineColor,
      };
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'typography/t.headings';
</style>
