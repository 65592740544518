<template>
  <input
    v-model="value"
    :type="type"
    :required="required"
    class="mc-text-input"
    :class="setClasses"
    :aria-invalid="isInvalid"
    spellcheck="false"
    v-bind="{ ...$attrs }"
  />
</template>

<script>
import {
  responsiveModifiers,
  responsiveModifierValidators,
} from '../../utils/mozaicClasses';

const supportedTypes = [
  'date',
  'email',
  'number',
  'password',
  'tel',
  'text',
  'time',
  'search',
  'month',
  'week',
  'datetime-local',
];

export default {
  name: 'MTextInputField',
  props: {
    type: {
      type: String,
      default: 'text',
      validator: (type) => supportedTypes.includes(type),
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'm',
      validator: (value) => responsiveModifierValidators(value, ['s', 'm']),
    },
    iconPosition: {
      type: String,
      default: 'left',
      validator: (value) => value === 'left' || value === 'right',
    },
  },
  emits: ['update:modelValue'],
  computed: {
    setClasses() {
      const classes = [];

      if (this.isValid) {
        classes.push('is-valid');
      }

      if (this.isInvalid) {
        classes.push('is-invalid');
      }

      if (this.icon && this.iconPosition === 'left') {
        classes.push('mc-left-icon-input__input');
      }

      if (this.icon && this.iconPosition === 'right') {
        classes.push('mc-right-icon-input__input');
      }

      if (this.size) {
        responsiveModifiers('mc-text-input', this.size, classes);
      }

      return classes;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.text-input';
</style>
