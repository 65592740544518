<template>
  <div class="mc-datatable__count mc-datatable__info">
    <slot>
      <strong> {{ start }} - {{ end }} </strong> {{ label }}
      <strong>{{ total }} {{ labelItems }}</strong>
    </slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({ name: 'MDataTableInfo' });
</script>

<script setup>
defineProps({
  label: {
    type: String,
    default: 'of',
  },
  labelItems: {
    type: String,
    default: 'items',
  },
  start: {
    type: Number,
    default: undefined,
  },
  end: {
    type: Number,
    default: undefined,
  },
  total: {
    type: Number,
    default: undefined,
  },
});
</script>
