import { toRaw } from 'vue';

function findMenuTreeWithCode(items, code) {
  const selection = [];
  for (const item of items) {
    if (item.code === code) {
      selection.push(item);
      return selection;
    } else if (item.items) {
      const prevSelection = findMenuTreeWithCode(item.items, code);
      if (prevSelection.length) {
        return [item, ...prevSelection];
      }
    }
  }
  return selection;
}

export function selectFeature(features, utilities, userFeatures, home, code) {
  let items = [...features, ...flatten(utilities), ...userFeatures];
  if (home) {
    items = [...items, home];
  }
  if (!items.length) {
    return;
  }
  if (!code) {
    return undefined;
  }

  const tree = findMenuTreeWithCode(items, code);
  if (tree.length) {
    return {
      lv0: toRaw(tree[0]),
      lv1: toRaw(tree[1]),
      lv2: toRaw(tree[2]),
    };
  } else {
    return undefined;
  }
}

export function flatten(arrayOfArrays) {
  return arrayOfArrays.reduce((accumulator, array) => {
    return [...accumulator, ...array];
  }, []);
}
