<template>
  <div class="mc-radio" :class="rootClass">
    <input
      :id="id"
      type="radio"
      class="mc-radio__input"
      :class="{ 'is-invalid': isInvalid }"
      v-bind="$attrs"
      :checked="modelValue"
      @change="onChange($event.target.checked)"
    />
    <label :for="id" class="mc-radio__label">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
  </div>
</template>

<script>
/**
 * > A radio button is used to offer a unique choice to your user in a form. Unlike checkboxes, it can not be used alone.
 *
 * The `MRadio` component is the **Vue.js** implementation of the **Radio** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Form/Radio/).
 */
export default {
  name: 'MRadio',
  inheritAttrs: false,
  props: {
    /**
     * The value of the `id` attribute of the input element
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * The label of the Input Radio
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * The value of the `checked` attribute of the input element. Can be used with `v-model`.
     */
    modelValue: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to define the component as invalid
     */
    isInvalid: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to place a CSS class on the root element of the component
     */
    rootClass: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    onChange(checked) {
      /**
       * Emits the state value of the "checked" state
       */
      this.$emit('update:modelValue', checked);
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.radio';
</style>
