<template>
  <component :is="htmlTag" class="mc-flag" :class="classObject">
    <span class="mc-flag__label">{{ label }}</span>
  </component>
</template>

<script>
import { checkThemeValue } from '../../utils/theme.validator';

/**
 * > A flag is used to display meta-informations about a product or a service. It must be displayed at the top of the content, and used as an indicator of a content main category.
 *
 * The `MFlag` component is the **Vue.js** implementation of the **Flag** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Flags/).
 */
export default {
  name: 'MFlag',
  props: {
    /**
     * The Flag label
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Allows you to set the colour theme of the Flag
     * @values bordered, solid-dark, bordered-dark, 'solid-light', 'bordered-light', solid-danger, bordered-danger
     */
    theme: {
      type: String,
      default: null,
      validator: (value) => checkThemeValue(value),
    },
    /**
     * Allows to define the HTML tag to be used for the Flag element
     */
    htmlTag: {
      type: String,
      default: 'div',
    },
  },
  computed: {
    classObject() {
      return {
        [`mc-flag--${this.theme}`]: this.theme,
      };
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.flag';
</style>
