<template>
  <div class="mc-stars-result" :class="classObject">
    <span class="mc-stars-result__visual" />
    <span class="mc-stars-result__text">
      {{ scoreLabel }}: {{ closestScore }}/{{ ratingValue }}
    </span>
  </div>
</template>

<script>
import {
  responsiveModifiers,
  responsiveModifierValidators,
} from '../../utils/mozaicClasses';

const RATINGVALUE = 5;

/**
 * > Rating stars is a pattern you'll find in almost every website. This use case is made to display notes from 0 to 5. This case allows half values, like 2,5 stars for example. This case shows the results of user rated products or services.
 *
 * The `MStarsResult` component is the **Vue.js** implementation of the **Rating stars result** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/RatingStars/#rating-stars-result).
 */
export default {
  name: 'MStarsResult',
  props: {
    /**
     * The score value _(a value between `0` & `5` in increments of `0.5`)_
     */
    score: {
      type: Number,
      default: 0,
      validator: (value) => value > 0 && value <= 5,
    },
    /**
     * Allows to insert an explicit text to make the global score accessible
     */
    scoreLabel: {
      type: String,
      default: 'Global score',
    },
    /**
     * The size of the stars
     * @values s, m, l, xl
     */
    size: {
      type: String,
      default: 'm',
      validator: (value) =>
        responsiveModifierValidators(value, ['s', 'm', 'l', 'xl']),
    },
  },
  data() {
    return {
      id: Math.random().toString().split('.')[1],
      ratingValue: RATINGVALUE,
    };
  },
  computed: {
    authorizedValues() {
      const authorizedValue = [];
      for (let i = 0; i <= RATINGVALUE; i += 0.5) {
        authorizedValue.push(i);
      }
      return authorizedValue;
    },
    closestScore() {
      if (this.score > 0 && this.score <= this.ratingValue) {
        return this.authorizedValues.reduce((a, b) => {
          return Math.abs(b - this.score) < Math.abs(a - this.score) ? b : a;
        });
      } else {
        return 0;
      }
    },
    classObject() {
      const classes = [];

      if (this.size) {
        responsiveModifiers('mc-stars-result', this.size, classes);
      }

      if (this.closestScore) {
        const classNote = `score-${
          (this.closestScore * 100) / this.ratingValue
        }`;
        responsiveModifiers('mc-stars-result', classNote, classes);
      }

      return classes;
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.stars-result';
</style>
