<template>
  <div class="mc-datatable__select mc-datatable__rowsperpage">
    <label
      for="itemPerPage"
      class="mc-datatable__select-label mc-datatable__rowsperpage-label"
    >
      {{ props.label }}
    </label>
    <MSelect
      id="itemPerPage"
      size="s"
      :model-value="modelValue"
      :disabled="loading"
      :options="options"
      @update:model-value="$emit('update:modelValue', Number($event))"
    >
      <template #text="{ option }">
        <slot name="pager.text" v-bind="{ option }"> {{ option.text }} </slot>
      </template>
    </MSelect>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MDataTableRowsPerPage',
});
</script>

<script setup>
import MSelect from '../select/MSelect.vue';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: Number,
    default: undefined,
  },
});

defineEmits(['update:modelValue']);
</script>
