<template>
  <tr :class="parentRowClasses">
    <slot name="checkbox"></slot>

    <component
      :is="!selectable ? 'th' : 'td'"
      v-if="expandable"
      :scope="!selectable ? 'row' : null"
    >
      <button
        v-if="expandableList?.includes(rowIndex) || !expandableList"
        type="button"
        class="mc-datatable__expand mc-datatable__btn"
        @click="onClickExpander($event, item, rowIndex)"
      >
        <span class="mc-datatable__btn-label">
          {{ expanderAccessibleLabel }}
        </span>
        <MIcon
          v-if="!isExpanded"
          name="ControlCircleMore24"
          class="mc-datatable__btn-icon"
        />
        <MIcon
          v-else
          name="ControlCircleLess24"
          class="mc-datatable__btn-icon"
        />
      </button>
    </component>
    <slot name="cells"></slot>
  </tr>
  <tr
    v-if="expandable"
    class="mc-datatable__row-expandable"
    :class="expandableRowClasses"
  >
    <td :colspan="expandableRowColspan">
      <div class="mc-datatable__row-inner">
        <slot name="expandContent" :item="item" :row-index="rowIndex"></slot>
      </div>
    </td>
  </tr>
</template>

<script>
import { computed, defineComponent, defineProps, ref, onMounted } from 'vue';

export default defineComponent({ name: 'MDataTableRow' });
</script>

<script setup>
import MIcon from '../icon/MIcon.vue';

/* Interface: Props */
const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
  rowIndex: {
    type: Number,
    default: undefined,
  },
  headers: {
    type: Array,
    required: true,
  },
  indexKey: {
    type: String,
    default: '',
  },
  clickableRows: {
    type: Boolean,
    default: false,
  },
  selectable: {
    type: Boolean,
    default: false,
  },
  expandable: {
    type: Boolean,
    default: false,
  },
  showExpandableContent: {
    type: Boolean,
    default: false
  },
  expanderAccessibleLabel: {
    type: String,
    default: 'Déplier la ligne',
  },
  expandableList: {
    type: Array,
    default: undefined,
  },
});

/* Interface: Emits */
const emit = defineEmits(['click-row', 'expand-row', 'retract-row']);

/** Local State: data */
const isExpanded = ref(false);

onMounted(()=> {
  if (props.showExpandableContent) {
    isExpanded.value = props.expandableList?.includes(props.rowIndex) ?? true;
  }
});

/** Local State: computed */
const parentRowClasses = computed(() => {
  return {
    'mc-datatable-row__clickable': props.clickableRows,
    'mc-datatable__row-parent': props.expandable,
    'mc-datatable__row-parent--expanded': isExpanded.value,
  };
});

const expandableRowClasses = computed(() => {
  return {
    'mc-datatable__row-expandable--offset-1': props.expandable,
    'mc-datatable__row-expandable--offset-2':
      props.expandable && props.clickableRows,
  };
});

const expandableRowColspan = computed(() => {
  const colspanValue = props.selectable
    ? props.headers.length + 2
    : props.headers.length + 1;

  return colspanValue;
});

/** Non-Reactive Properties: methods */
function onClickExpander(event, rowData, rowIndex) {
  isExpanded.value = !isExpanded.value;

  const eventName = isExpanded.value ? 'expand-row' : 'retract-row';
  const eventArg = { data: rowData, index: rowIndex };

  emit(eventName, eventArg);
}
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.datatable-subtable';

.mc-datatable {
  &-row__clickable {
    cursor: pointer;
  }

  &-row__inner,
  &__row-inner {
    background-color: $color-datatable-subtable-background;
  }
}
</style>
