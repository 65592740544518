<template>
  <div class="mc-loader" :class="classObject">
    <span class="mc-loader__spinner">
      <svg
        class="mc-loader__icon"
        xmlns="http://www.w3.org/2000/svg"
        :viewBox="getViewBox"
      >
        <circle class="mc-loader__path" cx="50%" cy="50%" :r="getPathR" />
      </svg>
    </span>

    <span v-if="text" class="mc-loader__text">{{ text }}</span>
  </div>
</template>

<script>
/**
 * The `MLoader` component is the **Vue.js** implementation of the **Loader** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Loader/).
 */
export default {
  name: 'MLoader',
  props: {
    /**
     * Loader size
     * @values s, m , l
     */
    size: {
      type: String,
      default: 'm',
      validator: (value) => ['s', 'm', 'l'].includes(value),
    },
    /**
     * Loader theme
     * @values dark, light, primary
     */
    theme: {
      type: String,
      default: 'primary',
      validator: (value) => ['dark', 'light', 'primary'].includes(value),
    },
    /**
     * Loader text - when using the loader inside an overlay
     */
    text: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    classObject() {
      return {
        [`mc-loader--${this.size}`]: this.size && this.size !== 'm',
        [`mc-loader--${this.theme}`]: this.theme && this.theme !== 'primary',
      };
    },
    getViewBox() {
      return this.size === 'm'
        ? '0 0 32 32'
        : this.size === 's'
        ? '0 0 24 24'
        : '0 0 64 64';
    },
    getPathR() {
      return this.size === 'm' ? 9 : this.size === 's' ? 6 : 19;
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.loader';
</style>
