<template>
  <MBottomBar class="mc-action-bar" :fixed="fixed">
    <div
      v-if="(status || loading) && statusLabel"
      class="mc-action-bar__status"
    >
      <MIcon
        v-if="!loading"
        :name="getStatusIcon"
        class="mc-action-bar__icon"
        :class="classObjectIcon"
      />
      <MLoader v-else size="s" />
      {{ statusLabel }}
    </div>

    <MButton
      v-if="labelSecondary"
      size="s"
      :label="labelSecondary"
      theme="bordered-neutral"
      :disabled="getDisabled"
      @click="onClickSecondary"
    />
    <MButton
      size="s"
      :label="labelPrimary"
      :disabled="getDisabled"
      @click="onClickPrimary"
    />
    <MButton
      v-if="labelDanger"
      size="s"
      :label="labelDanger"
      theme="bordered-danger"
      :disabled="getDisabled"
      @click="onClickDanger"
    />
  </MBottomBar>
</template>

<script setup>
import { computed } from 'vue';
import MBottomBar from '../bottombar/MBottomBar.vue';
import MButton from '../button/MButton.vue';
import MLoader from '../loader/MLoader.vue';
import MIcon from '../icon/MIcon.vue';

/* props */
const props = defineProps({
  /**
   * Button label linked to primary action
   */
  labelPrimary: {
    type: String,
    default: 'Primary',
    required: true,
  },
  /**
   * Button label linked to secondary action
   */
  labelSecondary: {
    type: String,
    default: null,
  },
  /**
   * Button label linked to the danger action
   */
  labelDanger: {
    type: String,
    default: null,
  },
  /**
   * Allows to define the size of the accordion
   * @values null, success, warning
   */
  status: {
    type: String,
    default: null,
    validator: (value) => [null, 'success', 'warning'].includes(value),
  },
  /**
   * Button label linked to the danger action
   */
  statusLabel: {
    type: String,
    default: 'Danger',
  },
  /**
   * Allows you to activate or deactivate the loading state
   */
  loading: {
    type: Boolean,
    default: false,
  },
  /**
   * Allows to disabled the component
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Apply a `position: fixed` to the component and position it at the bottom of the page
   */
  fixed: {
    type: Boolean,
    default: false,
  },
});

/* emits */
const emit = defineEmits(['click:secondary', 'click:primary', 'click:danger']);

/** computed */
const classObjectIcon = computed(() => {
  return {
    [`is-${props.status}`]: props.status,
  };
});

const getStatusIcon = computed(() => {
  return props.status && props.status === 'warning'
    ? 'NotificationWarning24'
    : 'NotificationCircleAvailable24';
});

const getDisabled = computed(() => {
  return props.disabled ? props.disabled : props.loading;
});

/** methods */
const onClickPrimary = (event) => {
  /**
   * Triggered each time the Primary button is clicked.
   */
  emit('click:primary', event);
};

const onClickSecondary = (event) => {
  /**
   * Triggered each time the Secondary button is clicked.
   */
  emit('click:secondary', event);
};

const onClickDanger = (event) => {
  /**
   * Triggered each time the Danger button is clicked.
   */
  emit('click:danger', event);
};
</script>

<style lang="scss" scoped>
@import 'settings-tools/all-settings';

.mc-action-bar {
  gap: $mu100;
  justify-content: flex-end;

  &__status {
    @include set-font-scale('05', 'm');

    align-items: center;
    display: flex;
    gap: $mu025;
  }

  &__icon {
    &.is-warning {
      fill: $color-warning-600;
    }
    &.is-success {
      fill: $color-success-600;
    }
  }
}
</style>
