<template>
  <div class="mc-phone-number" :class="classObject">
    <div
      v-if="isCountriesListOpened"
      class="mc-phone-number__overlay"
      @click="toggleCountriesList"
    />
    <div class="mc-phone-number__dropdown" @keydown="initKeyboardNav">
      <button
        id="dropdown_country"
        type="button"
        aria-haspopup="listbox"
        aria-labelledby="dropdown_country"
        class="mc-phone-number__button"
        :aria-expanded="isCountriesListOpened"
        @click="toggleCountriesList"
      >
        <CountryFlag
          v-if="!withoutFlags"
          class="flag"
          :country="selectedCountry.shortName"
          size="small"
        />
        <span class="mc-phone-number__indicator">
          {{ selectedCountry.isd }}
        </span>
      </button>
      <ul
        v-show="isCountriesListOpened"
        id="phone_number_list"
        ref="countriesList"
        tabindex="-1"
        role="listbox"
        aria-labelledby="phone_number_list"
        class="mc-phone-number__list"
        :aria-activedescendant="selectedCountry.isd"
      >
        <li
          v-for="country in countries"
          :key="country.isd"
          role="option"
          class="mc-phone-number__item"
          :class="{
            'mc-phone-number__item--focused':
              country.isd === selectedCountry.isd,
          }"
          :aria-selected="country.isd === selectedCountry.isd"
          @click="onChangeCountry(country)"
          @keyup.enter="onChangeCountry(country)"
        >
          <CountryFlag
            v-if="!withoutFlags"
            class="flag"
            :country="country.shortName"
            size="small"
          />
          <span class="mc-phone-number__country">{{ country.name }}</span>
          &nbsp;
          <span>{{ country.isd }}</span>
        </li>
      </ul>
    </div>
    <input
      id="smallField"
      type="tel"
      class="mc-phone-number__input mc-text-input mc-text-input--m"
      name="phone-number-input"
      :value="phoneNumber"
      :placeholder="placeholder"
      :maxlength="maxlength"
      v-bind="$attrs"
      spellcheck="false"
      @blur="onChangePhoneNumber"
      @focus="(event) => (formatOnBlur ? cleanFormat(event) : null)"
      @keyup="onKeyPressPhoneNumber"
      @keydown.backspace="
        (event) => (formatOnBlur ? null : backspaceFunction(event))
      "
    />
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag-next';
import {
  parsePhoneNumber,
  formatIncompletePhoneNumber,
  AsYouType,
} from 'libphonenumber-js';
import { getExampleNumber } from 'libphonenumber-js/max';
import examples from 'libphonenumber-js/examples.mobile.json';

/**
 * > Phone number input enables the user to input its phone number with an international country prefix in a form element.
 *
 * The `MPhoneNumber` component is the **Vue.js** implementation of the **Phone number input** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Form/PhoneNumberInput/).
 */
export default {
  name: 'MPhoneNumber',
  components: {
    CountryFlag,
  },
  props: {
    /**
     * An array of objects to define the countries items
     * @type {[{ isd: '+33', name: 'France', shortName: 'FR' }]}
     */
    countries: {
      type: Array,
      default: () => [
        {
          isd: '+33',
          name: 'France',
          shortName: 'FR',
        },
        {
          isd: '+39',
          name: 'Italia',
          shortName: 'IT',
        },
      ],
    },
    /**
     * The value of the component.
     */
    value: {
      type: [String, Number],
      default: '',
    },
    /**
     * The value of the `maxlength` attribute of the input element
     */
    maxlength: {
      type: Number,
      default: 25,
    },
    /**
     * Allows to display or not the flags
     */
    withoutFlags: {
      type: Boolean,
      default: false,
    },
    /**
     * Format the number only after the onBlur event
     */
    formatOnBlur: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'input',
    'blur',
    'change-country',
    'open-countries-list',
    'close-countries-list',
  ],
  data: () => ({
    isCountriesListOpened: false,
    selectedCountry: undefined,
    phoneNumber: undefined,
  }),
  computed: {
    classObject() {
      return {
        'mc-phone-number--focused': this.isCountriesListOpened,
      };
    },
    hasValue() {
      return this.value !== '';
    },
    placeholder() {
      return getExampleNumber(
        this.selectedCountry.shortName,
        examples
      ).formatNational();
    },
  },
  watch: {
    value() {
      this.init();
    },
  },
  created() {
    this.init();
    if (!this.hasValue) {
      this.selectedCountry = this.countries[0];
    }
  },
  methods: {
    init() {
      if (this.hasValue) {
        try {
          const parsedValue = parsePhoneNumber(
            this.value,
            this.countries[0].shortName
          );
          this.phoneNumber = parsedValue.formatNational();
          this.selectedCountry =
            this.countries.find(
              (country) => country.shortName === parsedValue.country
            ) || this.selectedCountry;
        } catch (error) {
          this.phoneNumber = this.value;
        } finally {
          this.selectedCountry =
            this.selectedCountry === undefined
              ? this.countries[0]
              : this.selectedCountry;
        }
      } else {
        this.phoneNumber = '';
      }
    },
    initKeyboardNav(event) {
      switch (event.keyCode) {
        case 40: // ARROW DOWN
        case 38: {
          // ARROW UP
          if (event.view && event.view.event) {
            event.view.event.preventDefault();
          }

          if (!this.isCountriesListOpened) {
            this.openCountriesList();
          }

          const tmpIndex = this.countries.findIndex(
            (country) => country.isd === this.selectedCountry.isd
          );
          let index = event.keyCode === 40 ? tmpIndex + 1 : tmpIndex - 1;

          if (index === -1 || index >= this.countries.length) {
            index = index === -1 ? this.countries.length - 1 : 0;
          }

          this.selectedCountry = this.countries[index];
          break;
        }
        case 13: // ENTER
          this.isCountriesListOpened
            ? this.onChangeCountry(this.selectedCountry)
            : this.openCountriesList();
          break;
        case 27: // ESC
          this.closeCountriesList();
          break;
      }
    },

    backspaceFunction(event) {
      const caretPosition = event.target.selectionStart;
      const char = event.target.value.charAt(event.target.selectionStart - 1);
      if (char === ' ' || char === ')' || char === '-') {
        event.target.value =
          event.target.value.slice(0, event.target.selectionStart - 1) +
          event.target.value.slice(event.target.selectionStart);
        event.target.selectionStart = caretPosition - 1;
        event.target.selectionEnd = caretPosition - 1;
      }
    },

    onKeyPressPhoneNumber(event) {
      if (this.formatOnBlur) {
        const phoneNumber = event ? event.target.value : this.phoneNumber;
        try {
          /**
           * Triggered when entering the field
           */
          this.$emit(
            'input',
            parsePhoneNumber(
              phoneNumber,
              this.selectedCountry.shortName
            ).formatInternational()
          );
        } catch (error) {
          /**
           * Triggered when entering the field
           */
          this.$emit(
            'input',
            formatIncompletePhoneNumber(
              phoneNumber,
              this.selectedCountry.shortName
            )
          );
        }
      } else {
        let caretPosition = event.target.selectionStart;
        if (
          (event.keyCode >= 48 && event.keyCode <= 57) ||
          (event.keyCode >= 96 && event.keyCode <= 105)
        ) {
          const asYouType = new AsYouType(this.selectedCountry.shortName);

          this.$nextTick(() => {
            const charBefore = event.target.value.charAt(caretPosition - 1);
            const charAfter = event.target.value.charAt(caretPosition + 1);
            caretPosition =
              charBefore === ' ' || charBefore === '-'
                ? caretPosition + 1
                : caretPosition;
            caretPosition =
              charAfter === '-' || charAfter === ')'
                ? caretPosition + 2
                : caretPosition;
            event.target.setSelectionRange(caretPosition, caretPosition);
          });
          try {
            /**
             * Triggered when entering the field
             */
            this.$emit('input', asYouType.input(event.target.value));
          } catch (error) {
            /**
             * Triggered when entering the field
             */
            this.$emit(
              'input',
              formatIncompletePhoneNumber(
                event.target.value,
                this.selectedCountry.shortName
              )
            );
          }
        }
      }
    },

    onChangePhoneNumber(event) {
      const phoneNumber = event ? event.target.value : this.phoneNumber;
      try {
        /**
         * Triggered by the onBlur event
         */
        this.$emit(
          'blur',
          parsePhoneNumber(
            phoneNumber,
            this.selectedCountry.shortName
          ).formatInternational()
        );
      } catch (error) {
        /**
         * Triggered by the onBlur event
         */
        this.$emit(
          'blur',
          formatIncompletePhoneNumber(
            phoneNumber,
            this.selectedCountry.shortName
          )
        );
      }

      if (phoneNumber) {
        this.phoneNumber = parsePhoneNumber(
          phoneNumber,
          this.selectedCountry.shortName
        ).formatNational();

        if (event && event.target) {
          event.target.value = parsePhoneNumber(
            phoneNumber,
            this.selectedCountry.shortName
          ).formatNational();
        }
      }
    },
    onChangeCountry(country) {
      this.selectedCountry = country;
      this.closeCountriesList();
      /**
       * Triggered when a new country is selected
       */
      this.$emit('change-country', country);
      if (this.hasValue) {
        this.onChangePhoneNumber();
      }
    },
    toggleCountriesList() {
      this.$refs.countriesList.offsetParent
        ? this.closeCountriesList()
        : this.openCountriesList();
    },
    openCountriesList() {
      /**
       * Triggered when the country list opens
       */
      this.$emit('open-countries-list');
      this.isCountriesListOpened = true;
    },
    closeCountriesList() {
      /**
       * Triggered when the country list closes
       */
      this.$emit('close-countries-list');
      this.isCountriesListOpened = false;
    },
    cleanFormat(event) {
      this.phoneNumber = formatIncompletePhoneNumber(event.target.value);
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.text-input';
@import 'components/c.phone-number';

.mc-phone-number {
  span.flag {
    /* stylelint-disable */
    background-repeat: no-repeat;
    transform: scale(0.46153846153);
    // For information:
    // The value 0.46153846153 is obtained by the calculation 24/52
    // 24 being the width in pixel desired by the design of the component
    // 52 being the width in pixel defined by the country-flag component
    /* stylelint-enable */
  }

  &__dropdown {
    z-index: 20;
  }
}
</style>
