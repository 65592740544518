<template>
  <fieldset class="mc-field mc-field--group">
    <legend v-if="legend" class="mc-field__legend">
      {{ legend }}
      <span
        v-if="requirementText"
        class="mc-field__requirement"
        aria-hidden="true"
      >
        {{ requirementText }}
      </span>
    </legend>
    <span v-if="helpId && helpText" :id="helpId" class="mc-field__help">
      {{ helpText }}
    </span>
    <div
      class="mc-field__container"
      :class="{ 'mc-field__container--inline': inline }"
    >
      <!--
        @ignore
        @event input
      -->
      <MRadio
        v-for="(option, index) in options"
        :id="option.id ? option.id : option.value"
        :key="option.id ? option.id : option.value"
        :is-invalid="isInvalid"
        :name="option.name"
        root-class="mc-field__item"
        :model-value="modelValue === option.value"
        :disabled="option.disabled || disabled"
        @update:model-value="
          (v) => (v ? emit('update:modelValue', option.value) : null)
        "
      >
        <template #label>
          <slot :name="`label${index + 1}`" :option="option">
            {{ option.label }}
          </slot>
        </template>
      </MRadio>
    </div>
    <span
      v-if="errorId && errorMessage"
      :id="errorId"
      class="mc-field__error-message"
    >
      {{ errorMessage }}
    </span>
  </fieldset>
</template>

<script>
/**
 * > A radio button is used to offer a unique choice to your user in a form. Unlike checkboxes, it can not be used alone.
 *
 * The `MRadioGroup` component is the **Vue.js** implementation of the **Radio** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Form/Radio/code/#implement-as-a-group).
 */
export default {
  name: 'MRadioGroup',
};
</script>

<script setup>
import MRadio from './MRadio.vue';

defineProps({
  /**
   * Label of the whole group of checkboxes
   */
  legend: {
    type: String,
    default: null,
  },
  /**
   * Property used to manage the values checked by v-model
   * (Do not use directly)
   * @ignore
   */
  modelValue: {
    type: String,
    default: null,
  },
  /**
   * An array of objects to provide all the values and attributes related to a checkbox
   */
  options: {
    type: Array,
    default() {
      return [];
    },
    validator(options) {
      return options.every(
        (o) =>
          'id' in o &&
          typeof o.id == 'string' &&
          'label' in o &&
          typeof o.label == 'string' &&
          'value' in o &&
          typeof o.value == 'string'
      );
    },
  },
  /**
   * Text that indicate if one or more elements of the group are mandatory
   */
  requirementText: {
    type: String,
    default: null,
  },
  /**
   * Text providing relevant or additional information to ensure that the user understands the purpose of the selection.
   */
  helpText: {
    type: String,
    default: null,
  },
  /**
   * For accessibility reasons a `helpId` must be defined when using a `helpText`
   */
  // TODO: See if it is possible to automatically generate this id in the @next version
  helpId: {
    type: String,
    default: null,
  },
  /**
   * Text below the group of checkboxes explaining the reason for the error following the user selection.
   */
  errorMessage: {
    type: String,
    default: null,
  },
  /**
   * For accessibility reasons a `errorId` must be defined when using a `errorMessage`
   */
  // TODO: See if it is possible to automatically generate this id in the @next version
  errorId: {
    type: String,
    default: null,
  },
  /**
   * Position the inputs horizontally
   */
  inline: {
    type: Boolean,
    default: false,
  },
  /**
   * Allows to define the component as invalid
   */
  isInvalid: {
    type: Boolean,
    default: false,
  },
  /**
   * Disabled all radio buttons
   */
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.fields';
</style>
