import MDataTableV2 from './MDataTableV2.vue';
import MDataTableHeaderV2 from './MDataTableHeaderV2.vue';
import MDataTableColumn from './MDataTableColumn.vue';
import MDataTableTop from './MDataTableTop.vue';

MDataTableV2.install = (app) => {
  app.component('MDataTableV2', MDataTableV2);
};

MDataTableHeaderV2.install = (app) => {
  app.component('MDataTableHeaderV2', MDataTableHeaderV2);
};

MDataTableColumn.install = (app) => {
  app.component('MDataTableColumn', MDataTableColumn);
};

MDataTableTop.install = (app) => {
  app.component('MDataTableTop', MDataTableTop);
};

export { MDataTableV2, MDataTableColumn, MDataTableHeaderV2, MDataTableTop };
