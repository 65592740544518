<template>
  <div class="mc-option-card" :class="classObject">
    <input
      :id="id"
      :type="setType"
      class="mc-option-card__input"
      :class="classObjectInput"
      :name="name"
      :required="required"
      :checked="modelValue"
      v-bind="inputAttrs"
      @change="onChange($event.target.checked)"
    />
    <label :for="id" class="mc-option-card__label">
      <span class="mc-option-card__label-text">{{ label }}</span>
    </label>
    <div class="mc-option-card__content">
      <!-- @slot Use this slot to insert the main content of an Option Card -->
      <slot />
    </div>
  </div>
</template>

<script>
/**
 * > The option card component allows confronting some options of an item (product or service) and can be used to set up or even filter a selection. The content of each option card must be a comparable synthesis to help the user make a choice.
 *
 * The `MOptionCard` component is the **Vue.js** implementation of the **Option card** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/OptionGroup/OptionCard/).
 */
export default {
  name: 'MOptionCard',
  props: {
    /**
     * The value of the `id` attribute of the Option Card
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * The Option Card label. Mandatory to improve the accessibility of the component
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * The value of the `type` attribute of the input element of the component
     */
    type: {
      type: String,
      default: 'radio',
      validator: (value) => ['radio', 'checkbox'].includes(value),
    },
    /**
     * The value of the `name` attribute of the input element of the component
     */
    name: {
      type: String,
      default: null,
    },
    /**
     * The value of the `checked` attribute of the input element of the component. _(Can be used with `v-model`)_
     */
    checked: {
      type: Boolean,
      default: false,
    },
    /**
     * The value of the `checked` attribute of the input element of the component. _(Can be used with `v-model`)_
     */
    modelValue: {
      type: Boolean,
      default: false,
    },
    /**
     * The value of the `required` attribute of the input element of the component
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows the `<input />` element to be centred in the height
     */
    centered: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows you to reduce the padding value present on the content container of the component
     */
    small: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows you to remove the padding value present on the content container of the component
     */
    noPadding: {
      type: Boolean,
      default: false,
    },
    /**
     * An array of objects that allows you to provide the input element of the component with any desired attribute
     */
    inputAttrs: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  computed: {
    classObject() {
      return {
        'mc-option-card--centered': this.centered,
        'mc-option-card--no-padding': this.noPadding,
        'mc-option-card--small': this.small,
      };
    },
    setType() {
      return this.type === 'checkbox' ? 'checkbox' : 'radio';
    },
    classObjectInput() {
      return {
        'mc-checkbox__input': this.type === 'checkbox',
        'mc-radio__input': this.type === 'radio',
      };
    },
  },
  methods: {
    onChange(checked) {
      /**
       * Emits the state value of the "checked" state
       */
      this.$emit('update:modelValue', checked);
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.checkbox';
@import 'components/c.radio';
@import 'components/c.option-card';

/* stylelint-disable */
// temporary fix
.mc-option-card {
  $parent: get-parent-selector(&);

  &__input {
    @include unify-parent('input') {
      &:checked ~ #{$parent}__content {
        label,
        button,
        [href],
        input,
        select,
        textarea,
        #{$parent}__focusable,
        [tabindex]:not([tabindex='-1']) {
          position: relative;
        }
      }
    }
  }
}
/* stylelint-enable */
</style>
