import MListBox from './MListBox.vue';
import MListBoxActions from './MListBoxActions.vue';

MListBox.install = (app) => {
  app.component('MListBox', MListBox);
};

MListBoxActions.install = (app) => {
  app.component('MListBoxActions', MListBoxActions);
};

export { MListBox, MListBoxActions };
