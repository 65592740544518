<template>
  <div class="mc-option-button" :class="classObject">
    <input
      :id="id"
      type="radio"
      class="mc-option-button__input"
      :name="name"
      :checked="modelValue"
      :disabled="disabled"
      v-bind="inputAttrs"
      @change="onChange($event.target.checked)"
    />
    <label :for="id" class="mc-option-button__label">
      {{ label }}
    </label>
  </div>
</template>

<script>
/**
 * > The option button component allows confronting some options and can be used inside a questionnaire or a survey. The content of each option button must be easily readable to help the user make a choice.
 *
 * The `MOptionButton` component is the **Vue.js** implementation of the **Option button** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/OptionGroup/OptionButton/).
 */
export default {
  name: 'MOptionButton',
  props: {
    /**
     * The value of the `id` attribute of the Option Button
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * The Option Button label
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * The value of the `name` attribute of the Option Button
     */
    name: {
      type: String,
      default: null,
    },
    /**
     * Allows to define the checked state of the Option Button. Can be used with `v-model`.
     * @deprecated
     * @ignore
     */
    checked: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to define the checked state of the Option Button. Can be used with `v-model`.
     */
    modelValue: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to disabled the Option Button
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows the Option Button to take up the entire available width
     */
    fullWidth: {
      type: Boolean,
      default: false,
    },
    /**
     * Object allowing to pass to the input tag all necessary attributes
     */
    inputAttrs: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  computed: {
    classObject() {
      return {
        'mc-option-button--full': this.fullWidth,
      };
    },
  },
  methods: {
    onChange(checked) {
      /**
       * Emits the state value of the "checked" state
       */
      this.$emit('update:modelValue', checked);
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.option-button';
</style>
