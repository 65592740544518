<template>
  <nav class="mc-breadcrumb" :class="classObject" aria-label="Breadcrumb">
    <ul class="mc-breadcrumb__container">
      <li
        v-for="(item, index) in items"
        :key="`breadcrumb-${index}`"
        class="mc-breadcrumb__item"
        :class="[{ 'is-active': item.isActive || item.active }]"
      >
        <!--
          Triggered when clicking on a breadcrumb item
          @event link-clicked
          @property {object} current item
        -->
        <MLink
          :href="item.href"
          :class="[classObjectLink, setCurrentClass(index)]"
          :aria-current="setAriaCurrent(index)"
          size="s"
          :router="item.router"
          @click="$emit('link-clicked', item)"
        >
          {{ item.text }}
        </MLink>
      </li>
    </ul>
  </nav>
</template>

<script>
import MLink from '../link/MLink.vue';

/**
 * > Breadcrumb is a secondary navigation allowing the user to understand where the current page is located in the website architecture and to go back to previous steps or levels when needed.
 *
 * The `MBreadcrumb` component is the **Vue.js** implementation of the **Breadcrumb** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Breadcrumb/).
 */
export default {
  name: 'MBreadcrumb',
  components: {
    MLink,
  },
  props: {
    /**
     * An array of objects to define the items of the Breadcrumb
     * @type {[{ text: 'Item text', href: '#itemlink', active: true }]}
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     * Enables the "dark mode" of the component to be used on dark backgrounds
     */
    dark: {
      type: Boolean,
      default: false,
    },
    /**
     * On lower resolutions allows to display only the previous level link of the current page
     * @see See [Documentation](https://mozaic.adeo.cloud/Components/Breadcrumb/code/#behaviours)
     */
    responsive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['link-clicked'],
  computed: {
    classObject() {
      return {
        'mc-breadcrumb--dark': this.dark,
        'mc-breadcrumb--responsive': this.responsive,
      };
    },
    classObjectLink() {
      return {
        'mc-link--light': this.dark,
      };
    },
  },
  methods: {
    setCurrentClass(index) {
      return { 'mc-breadcrumb__current': index === this.items.length - 1 };
    },
    setAriaCurrent(index) {
      return index != this.items.length - 1 ? null : 'page';
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.breadcrumb';
/* stylelint-disable */
.mc-link.mc-breadcrumb__current {
  cursor: default;
  text-decoration: none;

  &,
  &:active,
  &:focus,
  &:hover,
  &:visited {
    color: currentColor;
  }
}
/* stylelint-enable */
</style>
