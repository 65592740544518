import MStarsInput from './MStarsInput.vue';
import MStarsResult from './MStarsResult.vue';

MStarsInput.install = (app) => {
  app.component('MStarsInput', MStarsInput);
};

MStarsResult.install = (app) => {
  app.component('MStarsResult', MStarsResult);
};

export { MStarsInput, MStarsResult };
