import MCheckbox from './MCheckbox.vue';
import MCheckboxGroup from './MCheckboxGroup.vue';

MCheckbox.install = (app) => {
  app.component('MCheckbox', MCheckbox);
};

MCheckboxGroup.install = (app) => {
  app.component('MCheckboxGroup', MCheckboxGroup);
};

export { MCheckbox, MCheckboxGroup };
