import { unrefElement } from '@vueuse/core';
import { computed, onBeforeUnmount, reactive, watch } from 'vue';

/**
 * Get information about scrolling animation.
 * @param header @type {Ref<HTMLElement | undefined>} The header element ref.
 * @param isAnimate @type {Ref<boolean>} The computed property indicate if animation is active.
 * @returns Information about scrolling.
 */
export function useScrollDirection(header, isAnimate) {
  let originalScrollPos = 0;

  const state = reactive({
    direction: undefined
  });

  const direction = computed(() => state.direction);

  function onScroll(e) {
    const element = unrefElement(header);

    if (element) {
      const container = element.parentElement;

      if (container && e.target === container) {
        const currentScrollPos = container.scrollTop;

        if (currentScrollPos <= element.offsetHeight) {
          state.direction = undefined;
        } else if (currentScrollPos < originalScrollPos) {
          state.direction = 'up';
        } else {
          state.direction = 'down';
        }
        originalScrollPos = currentScrollPos;
      }
    }
  }

  watch(
    () => isAnimate.value,
    () => {
      if (isAnimate.value) {
        window.addEventListener('scroll', onScroll, { capture: true });
      } else {
        window.removeEventListener('scroll', onScroll, { capture: true });
      }
    },
    { immediate: true }
  );

  onBeforeUnmount(() => {
    if (isAnimate.value) {
      window.removeEventListener('scroll', onScroll, { capture: true });
    }
  });

  return {
    direction
  };
}
