<template>
  <component
    :is="setComponent"
    v-bind="setAttributes(feature)"
    class="mc-sidebar-feature"
    :class="classObject"
  >
    <span class="mc-sidebar-feature__container">
      <MIcon v-if="feature.disabled" name="PublishLock16" color="#92a2a9" />
      <span class="mc-sidebar-feature__label">
        {{ feature.label }}
      </span>
      <div
        v-if="showTooltip"
        aria-hidden="true"
        class="mc-sidebar-feature__label__tooltip"
      >
        <div class="mc-sidebar-feature__label__tooltip__pointer"></div>
        <span>
          {{ feature.label }}
        </span>
      </div>
      <MIcon
        v-if="feature.target === '_blank'"
        name="DisplayExternalLink16"
        color="#ffffff"
      />
    </span>
  </component>
</template>

<script>
import MIcon from '../icon/MIcon.vue';

export default {
  name: 'MSidebarFeature',
  components: { MIcon },
  props: {
    feature: {
      type: Object,
      default: () => {},
    },
    open: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isSelected: false,
      showTooltip: false,
    };
  },
  computed: {
    classObject() {
      return {
        'is-selected': this.isSelected,
        'is-open': this.open,
        'is-close': !this.open,
        'is-disabled': this.feature.disabled,
      };
    },
    setComponent() {
      const data = this.feature;
      const routerLink = data.route ? 'router-link' : null;
      const linkElement = data.href ? (data.disabled ? 'span' : 'a') : 'button';

      return routerLink ?? linkElement;
    },
  },
  watch: {
    open() {
      this.updateSelection();
    },
    selected: {
      handler: function() {
        this.updateSelection();
      },
      deep: true
    },
  },
  mounted() {
    this.updateSelection();
  },
  methods: {
    setAttributes(data) {
      const id = data.code;
      const href = data.href ?? null;
      const target = data.href && data.target ? data.target : null;
      const type = !data.href ? 'button' : null;
      const disabled = !data.href && data.disabled ? true : null;
      const to = data.route ?? null;

      return {
        ...data.attrs,
        id,
        href,
        target,
        to,
        type,
        disabled,
        'aria-label': this.open && data.label,
        'aria-disabled': data.disabled,
      };
    },
    updateSelection() {
      this.isSelected =
        ((this.selected?.lv1 &&
          this.feature.code === this.selected?.lv1?.code) ||
          (this.selected?.lv2 &&
            this.feature.code === this.selected?.lv2?.code)) ??
        false;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'settings-tools/all-settings';

$color-grey: #5b737d;
$selected-Link: #007f8c;

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

.mc-sidebar-feature {
  font-size: 16px;
  min-width: 200px;
  cursor: pointer;
  flex: 1;
  display: flex;
  text-decoration: none;
  color: white;
  line-height: 22px;
  overflow: hidden;
  background: none;
  border: none;
  padding: 0;
  text-align: left;
  font-family: inherit;
  text-decoration: none;

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;

    span {
      max-width: 200px;
      min-width: 200px;
    }
  }

  &__label {
    @include set-font-scale('04', 'm');

    flex: 1;
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    &__tooltip {
      opacity: 0;
      transition: 0s opacity;
      background-color: #5b737d;
      border: 1px solid white;
      border-radius: 4px;
      position: absolute;
      pointer-events: none;
      left: calc(100% - 16px);
      padding: 8px 16px;
      width: 188px;

      &__pointer {
        position: absolute;
        top: 50%;
        left: -8px;
        top: calc(50% - 6px);
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 8px solid white;

        & div {
          position: relative;
          top: -6px;
          left: 2px;
          width: 0;
          height: 0;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-right: 8px solid #5b737d;
        }
      }
    }

    &:hover + .mc-sidebar-feature__label__tooltip {
      opacity: 1;
      transition-delay: 1s;
    }
  }

  &__icon {
    margin-right: 8px;
  }

  &.is-selected {
    background: $selected-Link;
  }

  &.is-close {
    @include transition(padding 300ms ease-in-out);
    padding: 0px 0px;

    .mc-sidebar-feature__container {
      @include transition(padding-left 300ms ease-in-out);
      padding-left: 20px;
      width: 24px;
    }
  }

  &.is-open {
    @include transition(padding 300ms ease-in-out);
    padding: 0px 32px;

    .mc-sidebar-feature__container {
      @include transition(padding-left 300ms ease-in-out);
      padding-left: 32px;
    }
  }

  &.is-disabled {
    color: #92a2a9;
    fill: #92a2a9;
  }

  &:hover {
    background: #405d68;
  }

  &:focus-visible {
    box-shadow: none;
    border: none;
    outline: none;

    .mc-sidebar-feature__container {
      box-shadow: none;
      border: none;
      border-radius: 6px;
      outline: 1px solid #0b96cc;
    }
  }
}
</style>
