<template>
  <ul
    class="mc-listbox"
    aria-labelledby="listbox"
    :class="classObject"
    :style="{ '--listbox-width': maxWidth }"
  >
    <li v-if="multiple && selectAll" class="mc-listbox__item">
      <input
        :id="setItemId('selectall')"
        class="mc-listbox__input mc-checkbox__input"
        type="checkbox"
        :checked="getAllItemsSelected"
        :indeterminate="isIndeterminate"
        @change="handleSelectAll"
      />
      <label :for="setItemId('selectall')" class="mc-listbox__label">
        {{ labelSelectAll }}
      </label>
    </li>
    <li
      v-for="(item, index) in localItems"
      :key="item.id"
      :class="{ 'mc-listbox__item--disabled': item[dataDisabledExpr] }"
      class="mc-listbox__item"
    >
      <MIcon
        v-if="item.icon"
        :name="item.icon"
        class="mc-listbox__icon"
        color="#666666"
      />
      <input
        :id="setItemId(index)"
        v-model="localValue"
        class="mc-listbox__input"
        :class="{ 'mc-checkbox__input': multiple }"
        :disabled="item[dataDisabledExpr]"
        :type="multiple ? 'checkbox' : 'radio'"
        :value="item[dataValueExpr]"
        :name="!multiple ? `listboxradio-${uuid}` : null"
      />
      <label :for="setItemId(index)" class="mc-listbox__label">
        <!-- @slot Use this slot if you want to customize the label display of the listbox items -->
        <slot name="item" :item="item">
          {{ item[dataTextExpr] }}
        </slot>
      </label>
    </li>
  </ul>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
import MIcon from '../icon/MIcon.vue';

/**
 * The `MListBox` component is the **Vue.js** implementation of the **Listbox** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/ListBox/).
 */

/* props */
const props = defineProps({
  /**
   * An array of objects that allows you to provide all the data needed to generate the items of the Listbox.
   * @type {[{ label: 'First Item', value: 1 }, { label: 'Second Item', value: 2 }]}
   */
  items: {
    type: Array,
    required: true,
  },
  /**
   * Value of the selected item(s). _(Can be used with a v-model)_
   */
  modelValue: {
    type: [Array, String, Number],
    default: undefined,
  },
  /**
   * Allows to make the Listbox visible or hide it
   */
  open: {
    type: Boolean,
    default: false,
  },
  /**
   * Allows to activate the "multi-selection" version of the component
   */
  multiple: {
    type: Boolean,
    default: false,
  },
  /**
   * Allows to specify another key than `label` to define the label of the items in the Listbox
   */
  dataTextExpr: {
    type: String,
    default: 'label',
  },
  /**
   * Allows to specify another key than `value` to define the value of the items in the Listbox
   */
  dataValueExpr: {
    type: String,
    default: 'value',
  },
  /**
   * Allows to specify another key than `disabled` to define the value disabled of the items in the Listbox
   */
  dataDisabledExpr: {
    type: String,
    default: 'disabled',
  },
  /**
   * Allows you to define a maximum size for the component
   */
  maxWidth: {
    type: String,
    default: '17.875rem',
  },
  /**
   * Activate or not the "Select all" feature
   */
  selectAll: {
    type: Boolean,
    default: false,
  },
  /**
   * Label of the "Select all" item
   */
  labelSelectAll: {
    type: String,
    default: 'Select all',
  },
});

/* emits */
const emit = defineEmits(['update:modelValue', 'select-all', 'unselect-all']);

/* data & state */
const localItems = ref(null);
const localValue = ref(null);
const uuid = Math.random();

/* watch */
watch(
  () => props.items,
  (val) => {
    localItems.value = val;
  },
  { immediate: true }
);

watch(
  () => props.modelValue,
  (value) => {
    if (!value && props.multiple) {
      localValue.value = [];
    } else {
      localValue.value = value;
    }
  },
  { immediate: true }
);

watch(
  () => localValue.value,
  (value) => {
    /**
     * Triggered each time a value is selected. _(can be used with a v-model)_
     */
    emit('update:modelValue', value);
  }
);

/* computed */
const classObject = computed(() => {
  return {
    'is-open': props.open,
    'mc-listbox--multi': props.multiple,
  };
});

const getLocalItemsValues = computed(() => {
  return localItems.value.map((item) => item[props.dataValueExpr]);
});

const getAllItemsSelected = computed(() => {
  return getLocalItemsValues.value.every((item) => {
    return localValue.value.includes(item);
  });
});

const isIndeterminate = computed(() => {
  const hasSelectedValues = localValue.value.some((item) => {
    return getLocalItemsValues.value.includes(item);
  });

  return hasSelectedValues && !getAllItemsSelected.value;
});

/* methods */
function setItemId(index) {
  return `listboxItem-${index + uuid}`;
}

function handleSelectAll(event) {
  const checked = event.target.checked;
  const selectedValues = [];

  if (checked) {
    localItems.value.map((item) => {
      selectedValues.push(item[props.dataValueExpr]);
    });
    localValue.value = selectedValues;
    emit('select-all');
  } else {
    emit('unselect-all');
    localValue.value = [];
  }
}
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.checkbox';
@import 'components/c.listbox';

.mc-listbox__item--disabled {
  background-color: $color-primary-02-300;
}

.mc-listbox__input:disabled + .mc-listbox__label {
  cursor: not-allowed;
}
</style>
