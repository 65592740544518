<template>
  <nav class="mc-stepper" :class="classObject" :aria-label="getAriaLabel">
    <ol class="mc-stepper__list">
      <!--
        Triggered when clicking on a validated step
        @event step-changed
        @property {object} step object
      -->
      <li
        v-for="(step, idx) in steps"
        :key="`mc-stepper__item-${idx}`"
        class="mc-stepper__item"
        :class="{
          'mc-stepper__item--validated': isStepValidated(idx),
          'mc-stepper__item--current': step.isCurrent,
        }"
        :aria-current="step.isCurrent ? 'step' : false"
        :aria-label="stepDescription(step, idx)"
        :style="`--steps: ${steps.length}; --current: ${idx + 1};`"
        @click="isStepValidated(idx) && $emit('step-changed', step)"
      >
        <a
          v-if="step.href && isStepValidated(idx)"
          :href="step.href"
          class="mc-stepper__link"
        >
          <div class="mc-stepper__indicator" aria-hidden="true">
            <MIcon
              v-if="isStepValidated(idx)"
              name="NotificationAvailable16"
              class="mc-stepper__icon"
            />
            <span v-else-if="step.isCurrent">{{ idx + 1 }}</span>
          </div>
          <div class="mc-stepper__detail">
            <span class="mc-stepper__title">
              {{ idx + 1 }} / {{ steps.length }}
            </span>
            <span class="mc-stepper__label">{{ step.label }}</span>
          </div>
        </a>
        <template v-else>
          <div class="mc-stepper__indicator" aria-hidden="true">
            <MIcon
              v-if="isStepValidated(idx)"
              name="NotificationAvailable16"
              class="mc-stepper__icon"
            />
            <span v-else-if="step.isCurrent">{{ idx + 1 }}</span>
          </div>
          <div class="mc-stepper__detail">
            <span class="mc-stepper__title">
              {{ idx + 1 }} / {{ steps.length }}
            </span>
            <span class="mc-stepper__label">{{ step.label }}</span>
          </div>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>
import MIcon from '../icon/MIcon.vue';

/**
 * > A stepper visually indicates the progress of a user's journey on pre-defined steps to complete.
 *
 * The `MStepper` component is the **Vue.js** implementation of the **Stepper** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Stepper/).
 */
export default {
  name: 'MStepper',
  components: {
    MIcon,
  },
  props: {
    /**
     * An array of objects that allows you to provide all the information needed for each step
     */
    steps: {
      type: Array,
      required: true,
    },
    /**
     * An object which must contain the keys `stepperDescription`, `validatedLabel`, `currentLabel` & `disabledLabel`, to ensure that the component is accessible
     */
    accessibilityLabels: {
      type: Object,
      required: true,
    },
    /**
     * Allows to use the "compact" version of the Stepper
     * @see [Compact version](https://mozaic.adeo.cloud/Components/Stepper/#compact-version)
     */
    compact: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['step-changed'],
  computed: {
    classObject() {
      return {
        'mc-stepper--compact': this.compact,
        'mc-stepper--shrinked': this.steps.length > 3,
      };
    },
    getAriaLabel() {
      return this.accessibilityLabels.stepperDescription;
    },
  },
  methods: {
    isStepValidated(index) {
      return index < this.steps.findIndex((step) => step.isCurrent);
    },
    stepDescription(step, index) {
      return (
        '#' +
        (index + 1) +
        ' ' +
        step.label +
        ', ' +
        this.stepStateLabel(step, index)
      );
    },
    stepStateLabel(step, index) {
      return this.isStepValidated(index)
        ? this.accessibilityLabels.validatedLabel
        : step.isCurrent
        ? this.accessibilityLabels.currentLabel
        : this.accessibilityLabels.disabledLabel;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'settings-tools/all-settings';
@import 'components/c.stepper';
</style>
