<template>
  <div class="mc-toggle" :class="classObject">
    <input
      :id="id"
      :name="name"
      type="checkbox"
      class="mc-toggle__input"
      :checked="modelValue"
      :disabled="disabled"
      @change="onChange($event.target.checked)"
    />
    <label :for="id" class="mc-toggle__label">
      <span class="mc-toggle__content">
        {{ label }}
        <span
          v-if="stateLabelOff && stateLabelOn"
          class="mc-toggle__state"
          aria-hidden="true"
        >
          <span class="mc-toggle__off">{{ stateLabelOff }}</span>
          <span class="mc-toggle__on">{{ stateLabelOn }}</span>
        </span>
      </span>
    </label>
  </div>
</template>

<script>
import {
  responsiveModifierValidators,
  responsiveModifiers,
} from '../../utils/mozaicClasses';

/**
 * > A toggle is used to choose between two possibilities and when the user needs instant feedback. It is common to use toggles when you need to show or hide content and "on/off" switch.
 *
 * The `MToggle` component is the **Vue.js** implementation of the **Toggle** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Form/Toggle/).
 */
export default {
  name: 'MToggle',
  props: {
    /**
     * The value of the `id` attribute of the input element of the component
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * The label of the Toggle
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * The value of the `name` attribute of the input element of the component
     */
    name: {
      type: String,
      default: null,
    },
    /**
     * The value of the `checked` attribute of the input element of the component.
     */
    modelValue: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to disabled the Toggle
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * The Toggle size
     * @values s, m
     * @see [Available sizes](https://mozaic.adeo.cloud/Components/Form/Toggle/code/#sizes)
     */
    size: {
      type: String,
      default: 'm',
      validator: (value) => responsiveModifierValidators(value, ['s', 'm']),
    },
    /**
     * Experimental solution waiting on this issue https://github.com/adeo/mozaic-vue/issues/52
     * @deprecated
     * @ignore
     */
    responsiveSizeModifiers: {
      type: Array,
      default: null,
      validator: (array) =>
        array.every((e) =>
          responsiveModifierValidators(e, [
            's@from-m',
            's@from-l',
            's@from-xl',
            's@from-xxl',
            'm@from-m',
            'm@from-l',
            'm@from-xl',
            'm@from-xxl',
          ])
        ),
    },
    /**
     * Text to be displayed when the toggle is inactive
     */
    stateLabelOff: {
      type: String,
      default: null,
    },
    /**
     * Text to be displayed when the toggle is active
     */
    stateLabelOn: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    classObject() {
      const classes = [];

      if (this.size) {
        classes.push(`mc-toggle--${this.size}`);
      }

      if (
        this.responsiveSizeModifiers &&
        this.responsiveSizeModifiers.length > 0
      ) {
        for (const modifier of this.responsiveSizeModifiers) {
          responsiveModifiers('mc-toggle', modifier, classes);
        }
      }

      return classes;
    },
  },
  methods: {
    onChange(checked) {
      /**
       * Emits the state value of the "checked" state
       */
      this.$emit('update:modelValue', checked);
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.toggle';
</style>
