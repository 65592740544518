<template>
  <div v-if="icon" class="mc-left-icon-input" :class="rootClass">
    <MTextInputIcon :icon="icon" />
    <MTextInputField
      ref="mField"
      :class="textInputFieldClass"
      v-bind="{ ...$attrs, ...$props }"
    />
  </div>

  <MTextInputField v-else ref="mField" v-bind="{ ...$attrs, ...$props }" />
</template>

<script>
import MTextInputField from './MTextInputField.vue';
import MTextInputIcon from './MTextInputIcon.vue';

/**
 * > Inputs are used to create input fields with text on a single line. Their states depends on the user interaction or the context.
 *
 * The `MTextInput` component is the **Vue.js** implementation of the **Text input** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Form/TextInput/).
 */
export default {
  name: 'MTextInput',
  components: {
    MTextInputField,
    MTextInputIcon,
  },
  inheritAttrs: false,
  props: {
    /**
     * Displays an illustration icon in the input. _(Note that this icon is not clickable)_
     * @see [Available icons](https://mozaic.adeo.cloud/Foundations/Icons/)
     */
    icon: {
      type: String,
      default: null,
    },
    /**
     * Allows to place a CSS class on the root element of the component. _(To be used only if the prop icon is used)_
     */
    rootClass: {
      type: String,
      default: null,
    },
    /**
     * Allows to place a CSS class on the input element. _(To be used only if the prop icon is used)_
     */
    textInputFieldClass: {
      type: String,
      default: null,
    },
  },
};
</script>
