<template>
  <div class="mc-overlay" :class="{ 'is-visible': isVisible }">
    <!-- @slot Use this slot to insert a centered content inside the overlay -->
    <slot />
  </div>
</template>

<script>
/**
 * The `MOverlay` component is the **Vue.js** implementation of the **Overlay** component of Mozaic Design System.<br/>
 */
export default {
  name: 'MOverlay',
  props: {
    /**
     * Define if the overlay is visible or not
     */
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.overlay';
</style>
