<template>
  <div class="mc-password-input">
    <input
      :id="id"
      v-model="value"
      class="mc-text-input mc-password-input__control"
      :class="classObjectInput"
      :type="getInputType"
      :required="required"
      :disabled="disabled"
      :aria-invalid="isInvalid"
      spellcheck="false"
      :placeholder="placeholder"
    />
    <button
      ref="button"
      type="button"
      class="mc-password-input__button"
      role="switch"
      :aria-checked="getAriaChecked"
      :disabled="disabled"
      @click="onClickSwitchButton"
    >
      {{ getButtonLabel }}
    </button>
  </div>
</template>

<script>
/**
 * > Password input is a necessary security feature for logging in or registering on a website.
 *
 * The `MPasswordInput` component is the **Vue.js** implementation of the **Password input** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Form/PasswordInput/).
 */
export default {
  name: 'MPasswordInput',
  props: {
    /**
     * The value of the `id` attribute of the input element
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * The value of the input. _(Can be used with `v-model`)_
     */
    modelValue: {
      type: [String, Number],
      default: null,
    },
    /**
     * The value of the `required` attribute of the input element
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to disabled the component
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to define the component as valid
     */
    isValid: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to define the component as invalid
     */
    isInvalid: {
      type: Boolean,
      default: false,
    },
    /**
     * An JS object allowing to customize the "Show" or "Hide" texts of the button
     *  @type {{ show: 'Show', hide: 'Hide' }}
     */
    buttonLabel: {
      type: Object,
      default: function () {
        return { show: 'Show', hide: 'Hide' };
      },
    },
    /**
     * The value of the `placeholder` attribute of the input element
     */
    placeholder: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data: function () {
    return {
      isVisible: false,
    };
  },
  computed: {
    getInputType() {
      return this.isVisible ? 'text' : 'password';
    },
    classObjectInput() {
      return {
        'is-valid': this.isValid,
        'is-invalid': this.isInvalid,
      };
    },
    getAriaChecked() {
      return this.isVisible ? 'true' : 'false';
    },
    getButtonLabel() {
      return this.isVisible ? this.buttonLabel.hide : this.buttonLabel.show;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        /**
         * Emits the value of the field when entered
         */
        this.$emit('update:modelValue', newValue);
      },
    },
  },
  methods: {
    onClickSwitchButton() {
      const aria = this.getAriaChecked === 'false';
      this.isVisible = aria;
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.text-input';
@import 'components/c.password-input';
</style>
