import MOverlay from './MOverlay.vue';
import MOverlayLoader from './MOverlayLoader.vue';

MOverlay.install = (app) => {
  app.component('MOverlay', MOverlay);
};

MOverlayLoader.install = (app) => {
  app.component('MOverlayLoader', MOverlayLoader);
};

export { MOverlay, MOverlayLoader };
