<template>
  <component :is="htmlTag" class="ml-container" :class="classObject">
    <!-- @slot Use this slot to insert content inside the container -->
    <slot />
  </component>
</template>

<script>
/**
 * > The container defines the main content width as well as the minimum negative space between the horizontal edges of the screen and the content.
 *
 * The `MContainer` component is the **Vue.js** implementation of the **Container** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Foundations/Layout/Container/).
 */
export default {
  name: 'MContainer',
  props: {
    /**
     * Allows the container to be fluid
     * @see [Documentation](https://mozaic.adeo.cloud/Foundations/Layout/Container/#the-fluid-container)
     */
    fluid: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to define the HTML tag to be used for the container element
     */
    htmlTag: {
      type: String,
      default: 'div',
    },
  },
  computed: {
    classObject() {
      return {
        'ml-container--fluid': this.fluid,
      };
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'layouts/l.container';
@import 'utilities/u.margin';
@import 'utilities/u.padding';
</style>
