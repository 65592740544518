<template>
  <MIcon
    :id="`mc-text-input__icon-${id}`"
    class="mc-left-icon-input__icon"
    :name="icon"
  />
</template>

<script>
import MIcon from '../icon/MIcon.vue';

export default {
  name: 'MTextInputIcon',
  components: {
    MIcon,
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      id: Math.random().toString().split('.')[1],
    };
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.left-icon-input';
</style>
