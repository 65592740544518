<template>
  <component :is="htmlTag" class="mc-option-group">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'MOptionGroup',

  props: {
    htmlTag: {
      type: String,
      default: 'div',
    },
  },
};
</script>