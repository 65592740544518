<template>
  <span v-if="type === 'text'" class="mc-tag-text" :class="classObject">
    <span class="mc-tag-text__label">
      {{ label }}
    </span>
  </span>
  <a
    v-else-if="type === 'link'"
    class="mc-tag-link"
    :href="href"
    :class="classObject"
  >
    <span class="mc-tag-link__label">
      {{ label }}
    </span>
  </a>
  <span
    v-else-if="type === 'selectable'"
    class="mc-tag-selectable"
    :class="classObject"
  >
    <input
      :id="id"
      class="mc-tag-selectable__input"
      type="checkbox"
      :name="name"
      :value="value"
      :checked="modelValue"
      :required="required"
      :disabled="disabled"
      @change="onChangeSelectable"
    />
    <label class="mc-tag-selectable__pill" :for="id">
      <span class="mc-tag-selectable__label">
        {{ label }}
      </span>
    </label>
  </span>
  <span
    v-else-if="type === 'removable'"
    :id="id"
    class="mc-tag-removable"
    :class="classObject"
  >
    <span class="mc-tag-removable__label">
      {{ label }}
    </span>
    <button
      type="button"
      class="mc-tag-removable__remove"
      :disabled="disabled"
      @click="onClickRemovable(id)"
    />
  </span>
</template>

<script>
import {
  responsiveModifierValidators,
  responsiveModifiers,
} from '../../utils/mozaicClasses';

/**
 * > Tags are used to categorize, show filters or make selections. They display product properties.
 *
 * The `MTag` component is the **Vue.js** implementation of the **Tags** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Tag/).
 */
export default {
  name: 'MTag',
  props: {
    /**
     * The label of the Tag
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * The type of the Tag
     * @values text, link, selectable, removable
     */
    type: {
      type: String,
      default: 'text',
      validator: (value) =>
        ['text', 'link', 'selectable', 'removable'].includes(value),
    },
    /**
     * The theme of the Tag
     * @values light, dark
     */
    theme: {
      type: String,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value),
    },
    /**
     * The size of the Tag
     * @values s, s@from-m, s@from-l, s@from-xl, s@from-xxl, m, m@from-m, m@from-l, m@from-xl, m@from-xxl
     */
    size: {
      type: String,
      default: 'm',
      validator: (value) =>
        responsiveModifierValidators(value, [
          's',
          's@from-m',
          's@from-l',
          's@from-xl',
          's@from-xxl',
          'm',
          'm@from-m',
          'm@from-l',
          'm@from-xl',
          'm@from-xxl',
        ]),
    },
    /**
     * The href of the Tag _(To be used for a Link Tag)_
     */
    href: {
      type: String,
      default: null,
    },
    /**
     * The name of the input[type="checkbox"] inside the Tag _(To be used for a Selectable Tag)_
     */
    name: {
      type: String,
      default: null,
    },
    /**
     * The value of the `id` attribute of the tag
     */
    id: {
      type: [String, Number],
      required: true,
    },
    /**
     * The value of the input[type="checkbox"] inside the Tag _(To be used for a Selectable Tag)_
     */
    value: {
      type: String,
      default: null,
    },
    /**
     * The checked state of the input[type="checkbox"] inside the Tag _(To be used for a Selectable Tag)_
     */
    modelValue: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to disabled the Tag _(To be used for a Removable & a Selectable Tag)_
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows you to make a tag mandatory _(To be used for a Selectable Tag)_
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Experimental solution waiting on this issue https://github.com/adeo/mozaic-vue/issues/52
     * @deprecated
     * @ignore
     */
    responsiveSizeModifiers: {
      type: Array,
      default: null,
      validator: (array) =>
        array.every((e) =>
          responsiveModifierValidators(e, [
            's@from-m',
            's@from-l',
            's@from-xl',
            's@from-xxl',
            'm@from-m',
            'm@from-l',
            'm@from-xl',
            'm@from-xxl',
          ])
        ),
    },
  },
  emits: ['remove-tag', 'update:modelValue'],
  computed: {
    classObject() {
      const classes = [];

      if (this.size) {
        classes.push(`mc-tag-${this.type}--${this.size}`);
      }

      if (this.theme) {
        classes.push(`mc-tag-${this.type}--${this.theme}`);
      }

      if (
        this.responsiveSizeModifiers &&
        this.responsiveSizeModifiers.length > 0
      ) {
        for (const modifier of this.responsiveSizeModifiers) {
          responsiveModifiers('mc-tag', modifier, classes);
        }
      }

      return classes;
    },
  },
  methods: {
    onChangeSelectable($event) {
      /**
       * Triggers when the selectable Tag is checked or not _(can be used with a v-model)_
       */
      this.$emit('update:modelValue', $event.target.checked);
    },
    onClickRemovable(id) {
      /**
       * Triggers when the close button of the removable Tag is clicked
       *
       * - @arg {string} [id] the id of clicked tag
       */
      this.$emit('remove-tag', id);
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.tag';
</style>
