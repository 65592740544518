<template>
  <div
    ref="autocomplete"
    class="mc-autocomplete"
    :class="classObject"
    :style="setStyles"
  >
    <div class="mc-autocomplete__main">
      <MTag
        v-if="multiple && listboxValue.length > 0 && !hasTagList"
        :id="tagId ? tagId : `autocompleteTag-${uuid}`"
        ref="tag"
        :label="setTagLabel"
        :disabled="disabled"
        type="removable"
        class="mc-autocomplete__tag"
        size="s"
        @remove-tag="clearListbox()"
      />
      <MTextInput
        :id="id"
        v-model="inputValue"
        :placeholder="placeholder"
        :is-invalid="isInvalid"
        :is-valid="isValid"
        :disabled="disabled"
        :size="size"
        :root-class="getRootClass"
        text-input-field-class="mc-autocomplete__trigger"
        icon-position="left"
        icon="DisplaySearch48"
        autocomplete="off"
        @input="onInput($event.target.value)"
        @click="openState = true"
      />
      <div class="mc-autocomplete__tools">
        <MLoader v-if="loading" class="mc-autocomplete__loader" size="s" />
        <button
          v-if="isClearable"
          class="mc-autocomplete__clear"
          type="button"
          @click="onClear"
        >
          <MIcon name="ControlTagCross24" class="mc-autocomplete__clear-icon" />
          <span class="mc-autocomplete__clear-text">{{
            labelClearButton
          }}</span>
        </button>
      </div>
    </div>
    <MListBox
      v-if="!isFiltered"
      v-model="listboxValue"
      :open="!loading && openState"
      :items="localItems"
      :multiple="multiple"
      :empty-search-label="emptySearchLabel"
      :data-text-expr="dataTextExpr"
      :data-value-expr="dataValueExpr"
      :max-width="maxWidth"
      :select-all="selectAll"
      :label-select-all="labelSelectAll"
      @update:model-value="onChangeListbox"
      @select-all="$emit('select-all')"
      @unselect-all="$emit('unselect-all')"
    >
      <template #item="{ item }">
        <!-- @slot Use this slot if you want to customize the label display of the listbox items -->
        <slot name="item" :item="item" />
      </template>
    </MListBox>
    <div v-else class="mc-autocomplete__error">
      {{ emptySearchLabel }}
    </div>
    <!-- TODO: Plan to delete the "show-all" & "reduce" events, which are not very useful -->
    <MTagList
      v-if="hasTagList"
      :items="getTagList"
      v-bind="tagList"
      @remove-tag="onRemoveTag"
      @show-all="(value) => $emit('show-all', value)"
      @reduce="(value) => $emit('reduce', value)"
      @click:show-all="(value) => $emit('taglist:show-all', value)"
    />
  </div>
</template>

<script setup>
import { computed, nextTick, ref, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import MIcon from '../icon/MIcon.vue';
import MLoader from '../loader/MLoader.vue';
import MTag from '../tags/MTag.vue';
import MTextInput from '../textinput/MTextInput.vue';
import MListBox from '../listbox/MListBox.vue';
import MTagList from '../taglist/MTagList.vue';

/**
 * The `MAutocomplete` component is the **Vue.js** implementation of the **Autocomplete** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Form/Autocomplete/).
 */

/* props */
const props = defineProps({
  /**
   * Id of the **MTag** component. _(Only available for the multi-selection version of the component)_
   */
  tagId: {
    type: String,
    default: null,
  },
  /**
   * **MTag** component label. _(Only available for the multi-selection version of the component, it is displayed inside the tag, after the number of selected elements)_
   */
  tagLabel: {
    type: String,
    default: '',
  },
  /**
   * The input placeholder
   */
  placeholder: {
    type: String,
    default: null,
  },
  /**
   * Your personal function to filter the items in the list
   */
  filter: {
    type: Boolean,
    default: true,
  },
  /**
   * Component size
   * @values s, m
   */
  size: {
    type: String,
    default: 'm',
    validator: (value) => ['s', 'm'].includes(value),
  },
  /**
   * Allows to disabled the component
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Allows to define the component as invalid
   */
  invalid: {
    type: Boolean,
    default: false,
  },
  /**
   * Allows to define the component as valid
   */
  valid: {
    type: Boolean,
    default: false,
  },
  /**
   * The input value
   */
  input: {
    type: String,
    default: null,
  },
  /**
   * The value of the `id` attribute of the input element
   */
  id: {
    type: String,
    default: null,
  },
  /**
   * Allows you to activate or deactivate the loader
   */
  loading: {
    type: Boolean,
    default: false,
  },
  /**
   * The clear button label. _(required for accessibility)_
   */
  labelClearButton: {
    type: String,
    default: 'Clear',
  },
  /**
   * An array of objects that allows you to provide all the data needed to generate the items of the Listbox. _(can be used with a v-model)_
   * @type {[{ label: 'First Item', value: 1 }, { label: 'Second Item', value: 2 }]}
   */
  items: {
    type: Array,
    required: true,
  },
  /**
   * Value of the selected item(s). _(Can be used with a v-model)_
   */
  modelValue: {
    type: [Array, String, Number],
    default: undefined,
  },
  /**
   * Allows to make the Listbox visible or hide it
   */
  open: {
    type: Boolean,
    default: false,
  },
  /**
   * Allows to activate the "multi-selection" version of the component
   */
  multiple: {
    type: Boolean,
    default: false,
  },
  /**
   * Text to be displayed in case of error or invalid search
   */
  emptySearchLabel: {
    type: String,
    default: 'No results found',
  },
  /**
   * Allows to specify another key than `label` to define the label of the items in the Listbox
   */
  dataTextExpr: {
    type: String,
    default: 'label',
  },
  /**
   * Allows to specify another key than `value` to define the value of the items in the Listbox
   */
  dataValueExpr: {
    type: String,
    default: 'value',
  },
  /**
   * Enable (or not) the automatic sorting of the listbox items
   */
  sort: {
    type: Boolean,
    default: true,
  },
  /**
   * Show or hide the clear button
   */
  clearable: {
    type: Boolean,
    default: true,
  },
  /**
   * Allows you to define a maximum size for the component
   */
  maxWidth: {
    type: String,
    default: '100%', // 17.875rem
  },
  /**
   * An object that can contain all the accessories of the **MTagList** component
   * @type {Boolean}
   * @type {{ max: Number, showLabel: String, hideLabel: String, stateless: Boolean }}
   */
  tagList: {
    type: [Boolean, Object],
    default: false,
  },
  /**
   * Activate or not the "Select all" feature
   */
  selectAll: {
    type: Boolean,
    default: false,
  },
  /**
   * Label of the "Select all" item
   */
  labelSelectAll: {
    type: String,
    default: 'Select all',
  },
});

/* emits */
const emit = defineEmits([
  'update:modelValue',
  'filter',
  'clear',
  'update:open',
  'open',
  'close',
  'update:input',
  'clear-input',
  'show-all',
  'reduce',
  'taglist:show-all',
  'select-all',
  'unselect-all',
]);

/* refs */
const autocomplete = ref([]);
const tag = ref([]);

/* data & state */
const uuid = Math.random();
const openState = ref(props.open);
const tagWidth = ref('0px');
const tagValue = ref(null);
const inputValue = ref(null);
const localItems = ref(null);
const sortedListItems = ref(null);
const listboxValue = ref(null);
const isFiltered = ref(null);

/* watch */
watch(
  () => props.items,
  (value) => {
    localItems.value = value;
  },
  { immediate: true }
);

watch(
  () => localItems.value,
  (value) => {
    isFiltered.value = inputValue.value !== '' && !value.length;
    /**
     * Triggered when the listbox is filtered following the input
     */
    emit('filter', value);
  }
);

watch(
  () => props.input,
  (value) => {
    inputValue.value = value;
  },
  { immediate: true }
);

watch(
  () => props.modelValue,
  (value) => {
    if (!value && props.multiple) {
      listboxValue.value = [];
    } else {
      listboxValue.value = value;
    }
  },
  { immediate: true }
);

watch(
  () => listboxValue.value,
  (value) => {
    if (!props.multiple) {
      const selectedItems = getSelectedItems(value);
      const seletedLabels = selectedItems.map(
        (item) => item[props.dataTextExpr]
      );

      inputValue.value = seletedLabels.join(', ');
    } else {
      tagValue.value = value;
    }
  },
  { deep: true, immediate: true }
);

watch(
  tagValue,
  async () => {
    await nextTick();
    setTagWidth();
  },
  { immediate: true }
);

watch(openState, (value) => {
  const eventName = value ? 'open' : 'close';
  emit(eventName);
  /**
   * Triggered when the Listbox opening status is updated. _(can be used with a v-model)_
   */
  emit('update:open', value);
});

watch(
  () => props.open,
  (value) => {
    openState.value = value;
  }
);

/* computed */
const classObject = computed(() => {
  return {
    'mc-autocomplete--multi': props.multiple,
    'mc-autocomplete--clearable': isClearable.value,
  };
});

const setStyles = computed(() => {
  return {
    '--autocomplete-tag-width': tagWidth.value,
    '--autocomplete-width': props.maxWidth,
  };
});

const setTagLabel = computed(() => {
  return listboxValue.value.length.toString() + ' ' + props.tagLabel;
});

const isClearable = computed(() => {
  return props.clearable && inputValue?.value?.length > 0 && !props.disabled;
});

const isInvalid = computed(() => {
  return props.invalid || isFiltered.value;
});

const isValid = computed(() => {
  return props.valid;
});

const getInvalidRootClass = computed(() => {
  return (isInvalid.value && 'is-invalid') || null;
});

const getValidRootClass = computed(() => {
  return (isValid.value && 'is-valid') || null;
});

const getRootClass = computed(() => {
  return getInvalidRootClass.value || getValidRootClass.value;
});

const getTagList = computed(() => {
  const selectedItems = getSelectedItems();

  selectedItems.forEach((item) => {
    item.size = 's';
    item.type = 'removable';
    item.id = item.id ?? item.value;
  });

  return selectedItems;
});

const hasTagList = computed(() => {
  const hasTagList =
    typeof props.tagList === 'boolean'
      ? props.tagList
      : Object.keys(props.tagList).length;

  return props.multiple && props.tagList && hasTagList;
});

/* methods */
function setTagWidth() {
  tagWidth.value =
    tag.value && tag.value.$el ? `${tag.value.$el.clientWidth}px` : '0px';
}

function clearInput() {
  inputValue.value = '';
  onInput('');
}

function clearListbox() {
  listboxValue.value = props.multiple ? [] : undefined;
  /**
   * Triggered each time a value is selected. _(can be used with a v-model)_
   */
  emit('update:modelValue', listboxValue.value);
  /**
   * Triggered when the selected value is reset
   */
  emit('clear');
}

function onClear() {
  if (!props.multiple) {
    clearInput();
    clearListbox();
  } else {
    clearInput();
  }
}

function filterList(value) {
  if (value.length) {
    localItems.value = props.items.filter((item) =>
      item[props.dataTextExpr].toUpperCase().includes(value.toUpperCase())
    );
  } else {
    localItems.value = props.items;
  }
  /**
   * Triggered when the listbox is filtered following the input
   */
  emit('filter', localItems);
}

function closeListBox() {
  openState.value = false;

  if (props.multiple && props.sort) {
    sortItems();
  } else {
    localItems.value = props.items;
  }
}

function onChangeListbox() {
  /**
   * Triggered each time a value is selected. _(can be used with a v-model)_
   */
  emit('update:modelValue', listboxValue.value);

  if (!props.multiple) {
    closeListBox();
  }
}

function getSelectedItems(val) {
  const value = val ? val : listboxValue.value;
  const selectedItems = props.items.filter((item) => {
    return props.multiple
      ? value.includes(item[props.dataValueExpr])
      : value === item[props.dataValueExpr];
  });

  return selectedItems;
}

function sortItems() {
  sortedListItems.value = props.items;
  const selectedItems = ref(getSelectedItems());

  sortedListItems.value.sort((a, b) => {
    const hasItemA = selectedItems.value.includes(a);
    const hasItemB = selectedItems.value.includes(b);

    if (hasItemA === hasItemB) {
      return a[props.dataValueExpr] - b[props.dataValueExpr];
    } else if (hasItemA < hasItemB) {
      return 1;
    } else {
      return -1;
    }
  });

  localItems.value = sortedListItems.value;
}

function onInput(value) {
  if (value.length === 0) {
    /**
     * Triggered when the input field is cleared
     */
    emit('clear-input');
  }
  /**
   * Triggered each time a value is entered in the text field. _(can be used with a v-model)_
   */
  emit('update:input', value);

  if (props.filter) {
    filterList(value);
  }
}

function onRemoveTag(value) {
  listboxValue.value = listboxValue.value.filter((item) => item !== value);

  onChangeListbox();
}

/* rendering  */
onClickOutside(autocomplete, () => closeListBox());
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.autocomplete';

.mc-autocomplete {
  display: flex;
  flex-direction: column;
  gap: $mu150;

  &__main {
    position: relative;
  }

  &__error {
    @include set-font-scale('04', 'm');

    color: $color-fields-error;
    display: inline-block;
    margin-top: $mu025;
  }

  .mc-autocomplete__trigger {
    padding-right: $mu300;
  }

  .mc-listbox {
    top: 52px;
    margin-top: 0;
  }
}
</style>
