<template>
  <textarea
    class="mc-textarea"
    :class="{ 'is-valid': isValid, 'is-invalid': isInvalid }"
    :aria-invalid="isInvalid"
    :value="modelValue"
    spellcheck="false"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
/**
 * > A text area is used to fill multi-line text. They share appearence and states with text inputs.
 *
 * The `MTextArea` component is the **Vue.js** implementation of the **Text area** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Form/TextArea/).
 */
export default {
  name: 'MTextArea',
  props: {
    /**
     * The value of the Textarea. _(Can be used with `v-model`)_
     */
    modelValue: {
      type: String,
      default: null,
    },
    /**
     * Allows to define the component as valid
     */
    isValid: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to define the component as invalid
     */
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.textarea';
</style>
