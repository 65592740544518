<template>
  <div
    ref="layer"
    class="mc-layer"
    :class="{ 'mc-layer--ltr': position === 'left' }"
    role="dialog"
    aria-labelledby="layerTitle"
    :aria-modal="open"
    :aria-hidden="!open"
    tabindex="-1"
    v-bind="$attrs"
    @keyup.esc="closeLayer()"
  >
    <div class="mc-layer__dialog" :class="classObjectDialog" role="document">
      <div class="mc-layer__header">
        <MIcon v-if="headerIcon" :name="headerIcon" class="mc-layer__icon" />
        <component :is="layerTitleTag" :id="layerTitleId" class="mc-layer__title">
          {{ layerTitle }}
        </component>
        <button
          ref="close"
          class="mc-layer__close"
          type="button"
          @click="closeLayer()"
        >
          <span class="mc-layer__close-text">
            {{ closeButtonText }}
          </span>
        </button>
      </div>
      <div ref="body" class="mc-layer__body">
        <div ref="content" class="mc-layer__content">
          <!-- @slot Use this slot to insert the main content of the Layer -->
          <slot />
        </div>
      </div>
      <div v-if="$slots.footer" class="mc-layer__footer">
        <!--
          @slot Use this slot to insert the footer content of the Layer. A footer is mandatory!
          - [See the footer rules](https://mozaic.adeo.cloud/Components/Layers/#layer-footer)
        -->
        <slot name="footer" />
      </div>
    </div>
  </div>
  <div
    class="mc-layer-overlay"
    :class="{ 'is-visible': open }"
    tabindex="-1"
    role="dialog"
    @click="closeOnOverlay && closeLayer()"
  />
</template>

<script>
import MIcon from '../icon/MIcon.vue';

/**
 * > In modern layouts, a layer is a way to give further actions or display to the user specific content without breaking the user flow, like login or cart overview.
 *
 * The `MLayer` component is the **Vue.js** implementation of the **Layer** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Layers/).
 */
export default {
  name: 'MLayer',
  components: {
    MIcon,
  },
  props: {
    /**
     * Allows to make the Layer visible or hide it. Can be used with `v-model`.
     */
    open: {
      type: Boolean,
      default: false,
    },
    /**
     * The Layer's title. _(Title visible in the header. Mandatory for accessibility reasons)_
     */
    layerTitle: {
      type: String,
      required: true,
    },
    /**
     * Allows to define the HTML tag to be used for the Layer's title
     * @values h1, h2, h3, h4, h5, h6
     */
    layerTitleTag: {
      type: String,
      default: 'h2',
      validator: (value) =>
        ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value),
    },
    /**
     * Allows to define the id for the Layer's title
     */
    layerTitleId: {
      type: String,
      default: 'layerTitle'
    },
    /**
     * Allows to display an icon before the Layer's title
     * @see [Available icons](https://mozaic.adeo.cloud/Foundations/Icons/)
     */
    headerIcon: {
      type: String,
      default: null,
    },
    /**
     * The text content of the Layer's close button
     */
    closeButtonText: {
      type: String,
      default: 'Close',
    },
    /**
     * Enables or disables the "extended" variation
     * @see [Related documentation](https://mozaic.adeo.cloud/Components/Layers/code/#extended-layer)
     */
    extended: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to activate or not the closing of the layer when clicking on the overlay
     */
    closeOnOverlay: {
      type: Boolean,
      default: false,
    },
    /**
     * Enables or disables the "edge" variation
     * @see [Related documentation](https://mozaic.adeo.cloud/Components/Layers/code/#body-with-scrolling-content)
     */
    scrollbarEdge: {
      type: Boolean,
      default: false,
    },
    /**
     * Define the opening position of the layer
     */
    position: {
      type: String,
      default: 'right',
      validator: (value) => ['right', 'left'].includes(value),
    },
  },
  emits: ['update:open', 'layer-opened', 'layer-closed'],
  computed: {
    classObjectDialog() {
      return {
        'is-open': this.open,
        'mc-layer__dialog--extend': this.extended,
      };
    },
  },
  watch: {
    open(isOpen) {
      this.$nextTick(() => {
        if (isOpen) {
          this.trapFocus();
          document.body.classList.add('mc-layer-open');
          /**
           * Triggered when the layer is open
           */
          this.$emit('layer-opened');
        } else {
          document.body.classList.remove('mc-layer-open');
          /**
           * Triggered when the layer is close
           */
          this.$emit('layer-closed');
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setOverflow();
    });
  },
  methods: {
    closeLayer: function () {
      /**
       * Emits the state value of the "open" prop
       */
      this.$emit('update:open', false);
    },

    trapFocus: function () {
      const layer = document.querySelector('.mc-layer');
      const focusableElements = layer.querySelectorAll(
        'a[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstFocusableElement = focusableElements[0]; // get first element to be focused inside layer
      const lastFocusableElement =
        focusableElements[focusableElements.length - 1];

      document.addEventListener('keydown', function (e) {
        const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

        if (!isTabPressed) {
          return;
        }

        if (e.shiftKey) {
          // if shift key pressed for shift + tab combination
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus(); // add focus for the last focusable element
            e.preventDefault();
          }
        } else {
          // if tab key is pressed
          if (document.activeElement === lastFocusableElement) {
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            firstFocusableElement.focus(); // add focus for the first focusable element
            e.preventDefault();
          }
        }
      });
    },

    setOverflow: function () {
      const layer = this.$refs.layer;
      const body = this.$refs.body;
      const content = this.$refs.content;
      const overflowingClass = !this.scrollbarEdge
        ? 'mc-layer__body--overflowing'
        : 'mc-layer__body--overflowing-scroll-edge';

      const ro = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const bodyHeight =
            layer.querySelector('.mc-layer__body').clientHeight;
          const contentHeight = entry.target.scrollHeight;

          if (contentHeight > bodyHeight) {
            body.classList.add(overflowingClass);
          } else {
            body.classList.remove(overflowingClass);
          }
        }
      });

      ro.observe(content);
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.layer';
@import 'components/c.button';
</style>
