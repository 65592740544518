<template>
  <div class="mc-card" :class="classObject">
    <figure class="mc-card__visual" :class="classObjectVisual">
      <img
        :loading="lazy ? 'lazy' : 'eager'"
        :src="imgSrc"
        :alt="imgAlt"
        class="mc-card__img"
      />
      <!-- @slot Use this slot to manage the image in a more advanced way. -->
      <slot name="image" />
    </figure>
    <article class="mc-card__content">
      <component :is="titleTag" class="mc-card__title">
        {{ title }}
      </component>
      <component :is="subtitleTag" v-if="subtitle" class="mc-card__subtitle">
        {{ subtitle }}
      </component>
      <div class="mc-card__body">
        <!-- @slot Use this slot to insert the main content of the card. -->
        <slot />
      </div>
      <footer v-if="$slots.footer" class="mc-card__footer">
        <!--
          @slot Use this slot to insert content in the footer of the card. The footer content can only be a link or a button.
        -->
        <slot name="footer" />
      </footer>
    </article>
  </div>
</template>

<script>
/**
 * > A card is a graphic element that structures a group of related informations that must be understood on its own. It visually looks like a playing card.
 *
 * The `MCard` component is the **Vue.js** implementation of the **Card** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Cards/).
 */
export default {
  name: 'MCard',
  props: {
    /**
     * The card title
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * The src attribute of the card image
     */
    imgSrc: {
      type: String,
      required: true,
    },
    /**
     * Allows to define whether the value of the loading attribute of the image should be `lazy` _(true)_ or `eager` _(false)_
     */
    lazy: {
      type: Boolean,
      default: false,
    },
    /**
     * Enables the outlined variation of the card
     * @see [Documentation](https://mozaic.adeo.cloud/Components/Cards/#outlined-version)
     */
    outlined: {
      type: Boolean,
      default: false,
    },
    /**
     * Enables the fluid variation of the card
     */
    fluid: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to define the HTML tag to be used for the card title
     * @values h1, h2, h3, h4, h5, h6
     */
    titleTag: {
      type: String,
      default: 'h1',
      validator: (value) =>
        ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value),
    },
    /**
     * The alt attribute of the card image
     */
    imgAlt: {
      type: String,
      default: '',
    },
    /**
     * The card subtitle
     */
    subtitle: {
      type: String,
      default: null,
    },
    /**
     * Allows to define the HTML tag to be used for the card subtitle
     * @values h2, h3, h4, h5, h6
     */
    subtitleTag: {
      type: String,
      default: 'h2',
      validator: (value) => ['h2', 'h3', 'h4', 'h5', 'h6'].includes(value),
    },
    /**
     * Allows to define the card image ratio
     * @values 1x1, 4x3, 16x9
     */
    imageratio: {
      type: String,
      default: null,
      validator: (value) => ['1x1', '4x3', '16x9'].includes(value),
    },
  },
  computed: {
    classObject() {
      return {
        'mc-card--bordered': this.outlined,
        'mc-card--fluid': this.fluid,
      };
    },
    classObjectVisual() {
      return {
        [`mc-card__visual--${this.imageratio}`]: this.imageratio,
      };
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.card';
</style>
