<script>
export default {
  name: 'MDataTableHeader',

  props: {
    caption: {
      type: String,
      default: null,
    },
    dataFieldExpr: {
      type: String,
      default: null,
    },
    allowSorting: {
      type: Boolean,
      default: false,
    },
    sortOrder: {
      type: String,
      default: null,
      validator: function (value) {
        return [null, 'asc', 'desc'].indexOf(value) !== -1;
      },
    },
    sortFieldExpr: {
      type: String,
      default: null,
    },
    cssClass: {
      type: [Object, String],
      default: null,
    },
  },

  data() {
    return {
      options: {
        ...this.$props,
      },
      index: -1,
    };
  },

  created() {
    this.$parent.addHeader(this.options);
    this.index = this.$parent.headers.length;
  },

  unmounted() {
    this.$parent.removeHeader(this.index);
  },

  // Renderless component
  render: () => null,
};
</script>
