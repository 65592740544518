<template>
  <div class="mc-field">
    <label :for="id" class="mc-field__label">
      {{ label }}
      <span
        v-if="requirementText"
        class="mc-field__requirement"
        aria-hidden="true"
      >
        {{ requirementText }}
      </span>
    </label>
    <span v-if="helpId && helpText" :id="helpId" class="mc-field__help">
      {{ helpText }}
    </span>
    <div class="mc-field__element">
      <!-- @slot Use this slot to insert the form element of your choice -->
      <slot />
    </div>
    <span
      v-if="isInvalid && errorMessage"
      :id="errorId"
      class="mc-field__error-message"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
/**
 * > Fields enable the user to input content in a form. It displays a label, useful informations, an input and gives validation feedback.
 *
 * The `MField` component is the **Vue.js** implementation of the **Field** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Form/Field/).
 */
export default {
  name: 'MField',
  props: {
    /**
     * The value of the `id` attribute used on the form element contained in the **MField** slot. _Note that this id is also the value of the `for` attribute present on the MField label tag_
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * The text in the label tag
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Text allowing to specify that the filling of the field is mandatory for the field to be valid.
     * @see [Requirement](https://mozaic.adeo.cloud/Components/Form/Field/#requirement)
     */
    requirementText: {
      type: String,
      default: null,
    },
    /**
     * Text to guide the user during the input
     * @see [Help text](https://mozaic.adeo.cloud/Components/Form/Field/#help-text)
     */
    helpText: {
      type: String,
      default: null,
    },
    /**
     * The value of the `id` attribute set on the **helpText** element. _This value is mandatory when using a helpText in order to guarantee the accessibility of the component._
     */
    helpId: {
      type: String,
      default: null,
    },
    /**
     * Text indicating explicitly why the field is in error, and how to rectify the entry.
     */
    errorMessage: {
      type: String,
      default: null,
    },
    /**
     * The value of the `id` attribute set on the **errorMessage** element. _This value is mandatory when using a errorMessage in order to guarantee the accessibility of the component._
     */
    errorId: {
      type: String,
      default: null,
    },
    /**
     * @deprecated
     * @ignore
     */
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.fields';
</style>
