<template>
  <div ref="target" class="mc-listbox-options">
    <button
      ref="trigger"
      type="button"
      class="mc-listbox-options__trigger"
      @click="onClickTrigger"
    >
      <MIcon :name="icon" :color="triggerIconColor" />
      <span class="mc-listbox-options__trigger-label">{{ triggerLabel }}</span>
    </button>
    <Teleport to="body">
      <Transition @after-enter="onAfterEnter">
        <div
          v-show="isOpen"
          ref="listbox"
          class="mc-listbox-options__container"
          :class="classObjectListox"
          :style="styleObjectListbox"
          role="listbox"
          aria-labelledby="listbox"
        >
          <ul
            v-for="(list, i) in getItems"
            :key="`list${i}`"
            class="mc-listbox-options__list"
          >
            <li
              v-for="(item, j) in list"
              :key="`item${j}`"
              class="mc-listbox-options__tile"
              :class="{ 'is-disabled': item.disabled }"
            >
              <MIcon
                v-if="item.icon"
                :name="item.icon"
                class="mc-listbox-options__icon"
                :color="item.danger ? '#C61112' : '#71706B'"
              />
              <component
                :is="item.href ? 'a' : 'button'"
                :href="item.href ? item.href : null"
                :type="item.href ? null : 'button'"
                :disabled="item.disabled ? true : null"
                class="mc-listbox-options__item"
                :class="{
                  'is-danger': item.danger,
                  'is-disabled': item.disabled,
                }"
                @click.self="onClickItem(item, i, j)"
              >
                {{ item.text }}
              </component>
            </li>
          </ul>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script>
import { ref } from 'vue';
import { onClickOutside, useElementBounding } from '@vueuse/core';
import MIcon from '../icon/MIcon.vue';

export default {
  name: 'MListBoxActions',
  components: {
    MIcon,
  },
  props: {
    /**
     * An array of objects that allows you to provide all the data needed to generate the items of the Listbox.
     * @type {[{ label: 'First Item', value: 1 }, { label: 'Second Item', value: 2 }]}
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * Allows to make the Listbox visible or hide it
     */
    open: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows you to define whether the list should be left _(default)_ or right
     * @values left, right
     */
    position: {
      type: String,
      default: 'left',
    },
    /**
     * Opening button label _(useful for accessibility reasons)_
     */
    triggerLabel: {
      type: String,
      default: 'Display options',
    },
    /**
     * Allows to set a colour for the opening button
     */
    triggerIconColor: {
      type: String,
      default: 'currentColor',
    },
    /**
     * Name of the icon to be used in the opening button
     */
    icon: {
      type: String,
      default: 'DisplayOptions24',
    },
  },
  emits: ['update:itemSelected', 'item-clicked'],
  setup(props) {
    const target = ref(null);
    const trigger = ref(null);
    const listbox = ref(null);
    const isOpen = ref(props.open);
    const isVisible = ref(false);

    function closeListbox() {
      if (!isOpen.value) {
        return;
      }

      isOpen.value = false;
      isVisible.value = false;
    }

    onClickOutside(target, closeListbox);

    return { target, trigger, listbox, isOpen, isVisible, closeListbox };
  },
  data() {
    return {
      displayTop: false,
      listboxTop: '0px',
      listboxLeft: '0px',
    };
  },
  computed: {
    classObjectListox() {
      return {
        'is-open': this.isVisible,
        'align-right': this.position == 'right',
        'align-top': this.displayTop,
      };
    },
    styleObjectListbox() {
      return {
        top: this.listboxTop,
        left: this.listboxLeft,
      };
    },
    getItems() {
      const hasNestedArray = this.items.filter(Array.isArray).length;

      if (hasNestedArray === 0) {
        const listItems = [];
        listItems.push(this.items);

        return [this.items];
      }
      return this.items;
    },
  },
  created() {
    window.addEventListener('scroll', this.closeListbox, {
      capture: true,
    });
  },
  unmounted() {
    window.removeEventListener('scroll', this.closeListbox);
  },
  methods: {
    onClickItem(item, listIndex, itemIndex) {
      const valToEmit = Object.assign(
        { listIndex: listIndex, itemIndex: itemIndex },
        item
      );
      this.isOpen = false;
      this.$emit('update:itemSelected', valToEmit); // TODO: deprecated
      this.$emit('item-clicked', valToEmit);
    },
    onClickTrigger() {
      this.isOpen = !this.isOpen;
    },
    setListBoxPosition() {
      const { top, left, bottom } = useElementBounding(this.trigger);
      const listboxHeight = this.listbox.clientHeight;
      const topValue = Math.floor(bottom.value) + window.scrollY;
      const leftValue = Math.floor(left.value);

      this.listboxTop = `${topValue}px`;
      this.listboxLeft = `${leftValue}px`;

      if (
        top.value > listboxHeight &&
        listboxHeight + bottom.value >= window.innerHeight
      ) {
        this.displayTop = true;
      }
    },
    onAfterEnter() {
      this.setListBoxPosition();
      this.isVisible = true;
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';

.mc-listbox-options {
  display: inline-block;
  position: relative;

  &__trigger {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    height: $mu150;
    justify-content: center;
    padding: 0;
    width: $mu150;

    &-label {
      @include visually-hidden();
    }
  }

  &__container {
    background-color: $color-grey-000;
    border: 1px solid $color-grey-600;
    border-radius: 3px;
    left: 0;
    min-width: $mu800;
    overflow-y: auto;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    top: 100%;

    &.is-open {
      opacity: 1;
      visibility: visible;
      z-index: 11;
    }

    &.align-top {
      transform: translate(0, calc(-100% - #{$mu150}));
    }

    &.align-right {
      transform: translate(calc(-100% + #{$mu150}), 0);

      &.align-top {
        transform: translate(calc(-100% + #{$mu150}), calc(-100% - #{$mu150}));
      }
    }
  }

  &__list {
    @include unstyle-list();
    margin: 0 auto;
    padding: 8px 0;

    &::-webkit-scrollbar {
      background-color: $color-grey-100;
      width: $mu025;

      &-thumb {
        background: $color-grey-600;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #bab6bc;
    }
  }

  &__tile {
    align-items: center;
    color: $color-grey-900;
    display: flex;
    gap: $mu050;
    min-height: $mu250;
    padding-left: $mu075;
    padding-right: $mu075;
    position: relative;

    &:hover {
      background-color: #eeedea;
    }

    &.is-disabled {
      background-color: $color-grey-200;

      &,
      .mc-listbox-options__item {
        color: $color-grey-600;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  &__item {
    @include set-font-scale('05', 'm');

    background: none;
    border: none;
    color: currentColor;
    cursor: pointer;
    padding: 0;
    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 2;
    }

    &,
    &:active,
    &:hover,
    &:focus {
      text-decoration: none;
    }

    &.is-danger {
      color: #c61112;
    }
  }
}
</style>
