<template>
  <div class="mc-kpi" :class="classObject">
    <span v-if="labelOutsideContent" class="mc-kpi__label">
      {{ label }}
    </span>
    <div class="mc-kpi__content">
      <div class="mc-kpi__main">
        <span v-if="!labelOutsideContent" class="mc-kpi__label">
          {{ label }}
        </span>
        <span class="mc-kpi__value">{{ value }}</span>
      </div>
      <div v-if="icon || getDetail" ref="aside" class="mc-kpi__aside">
        <span v-if="getDetail" class="mc-kpi__detail">
          {{ getDetail }}
        </span>
        <MIcon v-if="icon" :name="icon" class="mc-kpi__icon" color="black" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import MIcon from '../icon/MIcon.vue';

/**
 * > The KPI (Key Performance Indicator) component is used to display data evolution.
 *
 * The `MKpi` component is the **Vue.js** implementation of the **Header** component of Mozaic Design System.<br/>
 */
/* props */
const props = defineProps({
  /**
   * Evolution value
   */
  value: {
    type: String,
    required: true,
  },
  /**
   * Icon that shows the data's evolution
   */
  icon: {
    type: String,
    default: undefined,
  },
  /**
   * Label
   */
  label: {
    type: String,
    default: undefined,
  },
  /**
   * Allows to define the KPI status
   * @values information, warning, danger, success, neutral
   */
  type: {
    type: String,
    default: 'information',
    validator: (value) =>
      ['information', 'warning', 'danger', 'success', 'neutral'].includes(
        value
      ),
  },
  /**
   * The evolution comment
   */
  detail: {
    type: String,
    default: undefined,
  },
  /**
   * @deprecated
   * @ignore
   */
  theme: {
    type: String,
    default: undefined,
  },
  /**
   * @deprecated
   * @ignore
   */
  expected: {
    type: String,
    default: undefined,
  },
});

const getSize = computed(() => {
  let size = 'small';

  if (props.label && !getDetail.value) {
    size = 'medium';
  } else if (getDetail.value) {
    size = 'large';
  }

  return size;
});

const labelOutsideContent = computed(() => {
  return props.label && getSize.value !== 'large';
});

const getDetail = computed(() => props.expected ?? props.detail);

const getType = computed(() => props.theme ?? props.type);

const classObject = computed(() => {
  return {
    [`mc-kpi--${getSize.value}`]: getSize.value,
    [`mc-kpi--${getType.value}`]: getType.value,
  };
});
</script>

<style lang="scss" scoped>
@import 'settings-tools/all-settings';

$kpi-type: (
  'information': (
    'background': #daeff7,
    'border': #007bb4,
    'text': #005c91,
  ),
  'warning': (
    'background': #fdf1e8,
    'border': #c65200,
    'text': #8c3500,
  ),
  'danger': (
    'background': #fdeaea,
    'border': #c61112,
    'text': #8c0003,
  ),
  'success': (
    'background': #ebf5de,
    'border': #188803,
    'text': #006902,
  ),
  'neutral': (
    'background': #e6e6e6,
    'border': #666666,
    'text': #4d4d4d,
  ),
);

$color-kpi-background: var(
  --color-kpi-background,
  map-deep-get($kpi-type, 'information', 'background')
);
$color-kpi-border: var(
  --color-kpi-border,
  map-deep-get($kpi-type, 'information', 'border')
);
$color-kpi-text: var(
  --color-kpi-text,
  map-deep-get($kpi-type, 'information', 'text')
);

@mixin set-kpi-container() {
  background-color: $color-kpi-background;
  border: 1px solid $color-kpi-border;
  border-radius: 4px;
  overflow: hidden;
}

.mc-kpi {
  $parent: get-parent-selector(&);

  color: $color-kpi-text;
  display: inline-block;

  // label
  &__label {
    @include set-font-scale('04', 'm');

    display: block;
  }

  // content
  &__content {
    display: flex;
  }

  // main
  &__main {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__value {
    @include set-font-scale('04', 'm');
    @include set-font-weight('semi-bold');
  }

  &__aside {
    align-items: center;
    background-color: $color-grey-000;
    color: $color-font-dark;
    display: flex;
    justify-content: center;
  }

  &__detail {
    @include set-font-scale('04', 'm');
  }

  &__icon {
    display: block;
    height: $mu100;
    width: $mu100;
  }

  // Modifier Size — Small
  &--small {
    #{$parent} {
      &__content {
        gap: $mu050;
      }

      &__main {
        @include set-kpi-container();

        padding: px-to-rem(3) px-to-rem(7);
      }

      &__aside {
        background-color: transparent;
      }
    }
  }

  // Modifier Size — Medium
  &--medium {
    #{$parent} {
      &__label {
        color: $color-grey-999;
        margin-bottom: px-to-rem(6);
      }

      &__content {
        @include set-kpi-container();
      }

      &__main {
        flex-grow: 1;
        padding: $mu050 $mu050 $mu050 px-to-rem(7);
      }

      &__value {
        @include set-font-scale('05', 'm');
      }

      &__aside {
        padding-left: $mu075;
        padding-right: px-to-rem(11);
      }
    }
  }

  // Modifier Size — Large
  &--large {
    @include set-kpi-container();

    #{$parent} {
      &__content {
        flex-direction: column;
        width: 100%;
      }

      &__main {
        flex-direction: column;
        padding: $mu100 px-to-rem(15);
      }

      &__label {
        @include set-font-scale('05', 'm');
      }

      &__value {
        @include set-font-scale('09', 'm');
      }

      &__aside {
        min-height: px-to-rem(47);
        padding-right: px-to-rem(11);
        padding-left: px-to-rem(11);

        gap: $mu075;
      }
    }
  }

  // Modifier Type
  @each $type, $props in $kpi-type {
    &--#{$type} {
      @if ($type != 'information') {
        --color-kpi-background: #{map-get($props, 'background')};
        --color-kpi-border: #{map-get($props, 'border')};
        --color-kpi-text: #{map-get($props, 'text')};
      }
    }
  }
}
</style>
