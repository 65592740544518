<template>
  <div
    class="mc-notification"
    :class="classObject"
    :role="(alert && 'alert') || null"
  >
    <div class="mc-notification__content">
      <component :is="titleTag" v-if="title" class="mc-notification__title">
        {{ title }}
      </component>
      <div class="mc-notification__message">
        <!-- @slot Use this slot to insert the main content of the Notification -->
        <slot />
      </div>
      <m-link
        v-if="link && linkContent"
        class="mc-notification__link"
        :href="link"
      >
        {{ linkContent }}
      </m-link>
      <div v-else-if="$slots.footer" class="mc-notification__footer">
        <!--
          @slot Use this slot to insert content in the footer of the Notification. The footer content can only be a link or a button.
        -->
        <slot name="footer" />
      </div>
    </div>
    <button
      v-if="closable"
      type="button"
      class="mc-notification-closable__close"
      title="Close"
      @click="onClickClose"
    />
  </div>
</template>

<script>
import MLink from '../link/MLink.vue';

/**
 * > Notifications are used to bring the user's attention on a piece of information that needs to be known. A notification is usually the result of an action made by the user.
 *
 * The `MNotification` component is the **Vue.js** implementation of the **Notification** component of Mozaic Design System.<br/>
 * The full specification of this component is available in [the associated documentation](https://mozaic.adeo.cloud/Components/Notification/).
 */
export default {
  name: 'MNotification',
  components: {
    MLink,
  },
  props: {
    /**
     * Allows to define the Notification type
     * @values information, success, warning, danger, neutral
     */
    type: {
      type: String,
      default: null,
      validator: (value) =>
        ['information', 'success', 'warning', 'danger'].includes(value),
    },
    /**
     * The title of the Notification
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * Allows to define the HTML tag to be used for the title of the Notification
     */
    titleTag: {
      type: String,
      default: 'h3',
    },
    /**
     * Should be used when the Notification is the result of a user action, to ensure the accessibility of the component.
     */
    alert: {
      type: Boolean,
      default: false,
    },
    /**
     * @deprecated - Use the footer slot instead
     * @ignore
     */
    link: {
      type: String,
      default: null,
    },
    /**
     * @deprecated - Use the footer slot instead
     * @ignore
     */
    linkContent: {
      type: String,
      default: null,
    },
    /**
     * Adds a close button to hide the Notification
     */
    closable: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows to define the size of the Notification
     * @values s, m
     */
    size: {
      type: String,
      default: null,
      validator: (value) => ['s', 'm'].includes(value),
    },
  },

  /**
   * Triggered each time the close button is clicked.
   */
  emits: ['close'],
  computed: {
    classObject() {
      return {
        [`mc-notification--${this.type}`]: this.type,
        [`mc-notification--${this.size}`]: this.size && this.size !== 'm',
        'mc-notification-closable': this.closable,
      };
    },
  },
  methods: {
    onClickClose() {
      /**
       * Triggered each time the close button is clicked.
       */
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.notification';
</style>
