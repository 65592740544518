const autorizedThemes = [
  'danger',
  'light',
  'neutral',
  'primary',
  'primary-02',
  'solid',
  'bordered',
  'solid-primary-02',
  'bordered-primary-02',
  'solid-dark',
  'bordered-dark',
  'solid-light',
  'bordered-light',
  'solid-danger',
  'bordered-danger',
];

export const checkThemeValue = (theme) => autorizedThemes.includes(theme);
